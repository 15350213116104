import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const HiveAiSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M12.013 1c.639 0 1.261.264 1.713.733.452.469.707 1.108.707 1.782v16.979a2.56 2.56 0 0 1-.707 1.773 2.388 2.388 0 0 1-1.713.733c-.64 0-1.262-.264-1.714-.733a2.56 2.56 0 0 1-.707-1.773V3.514c0-.664.256-1.304.707-1.78a2.354 2.354 0 0 1 1.714-.734Z" />
      <path d="M4.92 5.1c.64 0 1.262.273 1.714.75.452.477.708 1.134.708 1.816v8.668c0 .682-.256 1.33-.708 1.816a2.338 2.338 0 0 1-1.713.75 2.37 2.37 0 0 1-1.714-.75 2.648 2.648 0 0 1-.707-1.816V7.666c0-.682.256-1.33.707-1.816a2.371 2.371 0 0 1 1.714-.75Z" />
      <path d="M20.809 5.85a2.371 2.371 0 0 0-1.713-.75c-.64 0-1.262.264-1.714.75a2.664 2.664 0 0 0-.707 1.816v8.668c0 .682.255 1.339.707 1.816a2.37 2.37 0 0 0 1.714.75c.639 0 1.261-.264 1.713-.75a2.664 2.664 0 0 0 .707-1.816V7.666c0-.682-.255-1.339-.707-1.816Z" />
      <defs>
        <linearGradient id="hive--a" x1={12} x2={12} y1={1} y2={23} gradientUnits="userSpaceOnUse">
          <stop stopColor="#175CFF" />
          <stop offset={1} stopColor="#3693F9" />
        </linearGradient>
        <linearGradient id="hive--b" x1={12} x2={12} y1={1} y2={23} gradientUnits="userSpaceOnUse">
          <stop stopColor="#175CFF" />
          <stop offset={1} stopColor="#3693F9" />
        </linearGradient>
        <linearGradient id="hive--c" x1={12} x2={12} y1={1} y2={23} gradientUnits="userSpaceOnUse">
          <stop stopColor="#175CFF" />
          <stop offset={1} stopColor="#3693F9" />
        </linearGradient>
      </defs>
    </svg>
  )),
  { width: 24, height: 24 },
);
const HiveAi = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={HiveAiSvg} {...props} />
));
HiveAi.displayName = 'HiveAi';
export default HiveAi;
