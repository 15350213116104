import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const TwitterColoredSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      ref={ref}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="#1D9BF0"
        d="M20.69 7.526c.013.191.013.383.013.576 0 5.888-4.507 12.678-12.747 12.678v-.003a12.732 12.732 0 0 1-6.867-1.998 9.026 9.026 0 0 0 6.63-1.847 4.483 4.483 0 0 1-4.185-3.094 4.488 4.488 0 0 0 2.022-.077 4.462 4.462 0 0 1-3.593-4.367v-.057a4.47 4.47 0 0 0 2.033.558 4.447 4.447 0 0 1-1.387-5.95A12.738 12.738 0 0 0 11.842 8.6a4.443 4.443 0 0 1 1.296-4.257 4.502 4.502 0 0 1 6.339.193 9.019 9.019 0 0 0 2.845-1.081 4.477 4.477 0 0 1-1.97 2.464 8.948 8.948 0 0 0 2.573-.701 9.076 9.076 0 0 1-2.236 2.308Z"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const TwitterColored = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={TwitterColoredSvg} {...props} />
));
TwitterColored.displayName = 'TwitterColored';
export default TwitterColored;
