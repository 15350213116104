import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const NoAutomationOnTwotoneSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M14.046 2.28a2.63 2.63 0 0 1 1.005-.192c.358 0 .693.064 1.005.192.312.127.579.303.8.526.223.223.399.491.526.803.128.312.192.647.192 1.005 0 .355-.064.69-.192 1.001-.127.31-.303.576-.526.797a2.401 2.401 0 0 1-.8.523 2.63 2.63 0 0 1-1.005.191 2.63 2.63 0 0 1-1.005-.191 2.4 2.4 0 0 1-.8-.523 2.408 2.408 0 0 1-.522-.797 2.621 2.621 0 0 1-.192-1.001c0-.358.064-.693.192-1.005.127-.312.302-.58.523-.803.22-.223.487-.399.8-.526Zm1.832 1.456a1.082 1.082 0 0 0-.827-.352c-.332 0-.608.117-.827.352-.216.232-.325.525-.325.878 0 .35.109.643.325.875.219.23.495.345.827.345.333 0 .609-.115.827-.345.22-.232.328-.524.328-.875 0-.353-.109-.646-.328-.878Z"
        clipRule="evenodd"
      />
      <path d="M18.011 2.156h1.097l1.57 2.25v-2.25H22v4.902h-1.077l-1.582-2.239v2.239h-1.33V2.156Z" />
      <path
        fillRule="evenodd"
        d="M8.83 4a3.001 3.001 0 1 0 0 2h2.193V4H8.83ZM7 5a1 1 0 1 0-2 0 1 1 0 0 0 2 0ZM18 16a3 3 0 1 1-2.829 4H6.652a4.654 4.654 0 1 1 0-9.309h.349v2h-.349a2.654 2.654 0 1 0 0 5.309h8.52A3.001 3.001 0 0 1 18 16Zm1 3a1 1 0 1 0-2 0 1 1 0 0 0 2 0Z"
        clipRule="evenodd"
      />
      <path d="M17.655 10.691H17v2h.655a4.346 4.346 0 0 0 4.34-4.133h-2.004a2.346 2.346 0 0 1-2.336 2.133ZM12 10.586l2.293-2.293 1.415 1.414L13.415 12l2.293 2.293-1.415 1.414-2.292-2.293-2.293 2.293-1.415-1.414L10.586 12 8.293 9.707l1.415-1.414L12 10.586Z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const NoAutomationOnTwotone = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={NoAutomationOnTwotoneSvg} {...props} />
));
NoAutomationOnTwotone.displayName = 'NoAutomationOnTwotone';
export default NoAutomationOnTwotone;
