import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const BrEndlessTenacityForCustomersSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M21.561 11.252h1.441c-.387-2.41-2.469-4.25-4.977-4.25a4.984 4.984 0 0 0-3.35 1.278l-1.593 1.614.999.992L15.673 9.3a3.642 3.642 0 0 1 2.362-.873 3.624 3.624 0 0 1 3.526 2.825ZM9.34 15.723l1.587-1.609-.999-.992-1.586 1.582a3.642 3.642 0 0 1-2.363.873 3.624 3.624 0 0 1-3.526-2.825H1.002a5.053 5.053 0 0 0 4.987 4.25 4.984 4.984 0 0 0 3.35-1.28Z" />
      <path d="M21.561 12.752a3.624 3.624 0 0 1-3.526 2.825c-.906 0-1.72-.332-2.362-.873l-2.638-2.63-1.018-1.08L9.34 8.28a4.984 4.984 0 0 0-3.35-1.279c-2.518 0-4.6 1.84-4.987 4.25h1.45A3.624 3.624 0 0 1 5.98 8.427c.906 0 1.721.332 2.363.873l2.637 2.63 1.018 1.08 2.678 2.714A4.984 4.984 0 0 0 18.025 17c2.508 0 4.59-1.84 4.977-4.25h-1.44Z" />
    </svg>
  )),
  { width: 25, height: 25 },
);
const BrEndlessTenacityForCustomers = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={BrEndlessTenacityForCustomersSvg} {...props} />
));
BrEndlessTenacityForCustomers.displayName = 'BrEndlessTenacityForCustomers';
export default BrEndlessTenacityForCustomers;
