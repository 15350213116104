import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const UnarchiveSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M9.714 14.121 8.3 12.707 12.007 9l3.707 3.707-1.414 1.414-1.293-1.293v5.233h-2v-5.233l-1.293 1.293Z" />
      <path
        fillRule="evenodd"
        d="M4.465 2h15.07L22 5.697V19.5a2.5 2.5 0 0 1-2.5 2.5h-15A2.5 2.5 0 0 1 2 19.5V5.697L4.465 2Zm1.07 2L4.87 5H19.13l-.666-1H5.535ZM4 19.5V7h16v12.5a.5.5 0 0 1-.5.5h-15a.5.5 0 0 1-.5-.5Z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Unarchive = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={UnarchiveSvg} {...props} />
));
Unarchive.displayName = 'Unarchive';
export default Unarchive;
