import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const CustomFieldsFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M7.502 4h1.5v16h-1.5v2h5v-2h-1.5V4h1.5V2h-5v2ZM2.004 6v9.54a2.5 2.5 0 0 0 2.5 2.5H7.5V6H2.004ZM13 6h6.5A2.5 2.5 0 0 1 22 8.5v7a2.5 2.5 0 0 1-2.5 2.5h-6.498L13 6Z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const CustomFieldsFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={CustomFieldsFilledSvg} {...props} />
));
CustomFieldsFilled.displayName = 'CustomFieldsFilled';
export default CustomFieldsFilled;
