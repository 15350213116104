import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const BanFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M11.132 12.546 1.004 2.418l1.414-1.414L23 21.586 21.586 23l-2-2H3.996v-.5H4a8.001 8.001 0 0 1 7.132-7.954ZM14.415 10.189a4 4 0 1 0-5.604-5.604l5.604 5.604Z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const BanFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={BanFilledSvg} {...props} />
));
BanFilled.displayName = 'BanFilled';
export default BanFilled;
