import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const ApplicationsSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M22 6.5V18l-10 5-10-5V6.5l.362-.199a20 20 0 0 1 19.276 0L22 6.5ZM4 16.764V8.618l7 3.5v8.146l-7-3.5Zm9 3.5 7-3.5V8.618l-7 3.5v8.146ZM5.396 7.08a18.003 18.003 0 0 1 13.207 0L12 10.382 5.396 7.08Z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Applications = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={ApplicationsSvg} {...props} />
));
Applications.displayName = 'Applications';
export default Applications;
