import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const AndroidSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="m15.201 1.976.94-1.615c.077-.132.054-.286-.05-.34-.104-.056-.253.006-.33.138l-.96 1.652a8.036 8.036 0 0 0-2.832-.504c-1.023 0-1.99.184-2.853.512L8.151.16c-.077-.131-.226-.194-.33-.138-.103.056-.126.21-.05.34l.945 1.624c-1.965.883-3.288 2.542-3.288 4.444 0 .193.015.383.04.571h13c.028-.188.042-.378.042-.571 0-1.908-1.332-3.57-3.307-4.452l-.002-.001ZM3.501 16C4.324 16 5 15.317 5 14.483V9.517C5 8.683 4.326 8 3.5 8 2.675 8 2 8.683 2 9.517v4.966C2 15.318 2.676 16 3.5 16Zm17 0c.824 0 1.499-.683 1.499-1.517V9.517C22 8.683 21.326 8 20.5 8c-.825 0-1.5.683-1.5 1.517v4.966c0 .835.676 1.517 1.5 1.517ZM8.426 4.518c0 .322.256.582.572.582.316 0 .572-.26.572-.582A.577.577 0 0 0 9 3.935a.577.577 0 0 0-.572.583Zm6.541.582a.577.577 0 0 1-.572-.582c0-.322.256-.583.572-.583.316 0 .572.26.572.583a.577.577 0 0 1-.572.582ZM6 17.552V8h12v9.552h-.005A1.579 1.579 0 0 1 16.422 19H7.578a1.577 1.577 0 0 1-1.573-1.448H6ZM11.031 20v2.532A1.47 1.47 0 0 1 9.567 24a1.47 1.47 0 0 1-1.464-1.468v-2.53L11.03 20Zm4.88 2.532V20h-2.928v2.532A1.47 1.47 0 0 0 14.447 24a1.47 1.47 0 0 0 1.465-1.468Z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Android = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={AndroidSvg} {...props} />
));
Android.displayName = 'Android';
export default Android;
