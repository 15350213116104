import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const CallVideoCanceledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="m12.917 6.334 1.75-1.75-1.75-1.75 1.166-1.167 1.75 1.75 1.75-1.75 1.167 1.167L17 4.584l1.75 1.75L17.583 7.5l-1.75-1.75-1.75 1.75-1.166-1.166Z" />
      <path d="m12.31 4.584-1.75 1.75 3.524 3.524 1.75-1.75 1.75 1.75.75-.75v6.827l-4.168-3.438v2.087c0 1.15-.932 2.083-2.083 2.083H3.75a2.083 2.083 0 0 1-2.083-2.083V3.334h9.393l1.25 1.25Z" />
    </svg>
  )),
  { width: 20, height: 20 },
);
const CallVideoCanceled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={CallVideoCanceledSvg} {...props} />
));
CallVideoCanceled.displayName = 'CallVideoCanceled';
export default CallVideoCanceled;
