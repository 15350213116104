import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const UserAvatarSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M12 13a4 4 0 1 0 0-8 4 4 0 0 0 0 8Zm2-4a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M4.823 18.963A9.967 9.967 0 0 1 2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10a9.954 9.954 0 0 1-5.582-1.702 10.046 10.046 0 0 1-1.595-1.334Zm11.723-.38A7.964 7.964 0 0 1 12 20a7.963 7.963 0 0 1-4.545-1.416A5.986 5.986 0 0 1 12 16.5c1.817 0 3.445.808 4.545 2.084Zm1.49-1.333a7.981 7.981 0 0 0-6.035-2.75 7.981 7.981 0 0 0-6.037 2.75 8 8 0 1 1 12.073-.001Z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const UserAvatar = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={UserAvatarSvg} {...props} />
));
UserAvatar.displayName = 'UserAvatar';
export default UserAvatar;
