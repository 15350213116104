import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const MicOffFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M16.25 11.746c0 .664-.152 1.293-.424 1.853L7.75 5.523v-.277a4.25 4.25 0 0 1 8.5 0v6.5ZM12.547 15.961l1.242 1.242A5.5 5.5 0 0 1 6.5 12h-2a7.501 7.501 0 0 0 6.5 7.434V21H8v2h8v-2h-3v-1.566c.82-.11 1.6-.352 2.314-.705L20.585 24 22 22.586 1.418 2.004.004 3.418l7.746 7.746v.582a4.25 4.25 0 0 0 4.797 4.215ZM18.3 16.073A7.465 7.465 0 0 0 19.5 12h-2c0 .946-.239 1.836-.66 2.614l1.46 1.459Z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const MicOffFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={MicOffFilledSvg} {...props} />
));
MicOffFilled.displayName = 'MicOffFilled';
export default MicOffFilled;
