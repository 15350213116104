import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const PollsFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M7.185 8.396h6.065v-1.32a.25.25 0 0 0-.048-.148L9.618 2H1.75v6.245l2.26 2.937.89.544a5.291 5.291 0 0 0 2.442.765L5.987 9.437 5.262 7.63l1.392-.558.531 1.325Z" />
      <path
        fillRule="evenodd"
        d="M7 20v-6.032c.217.021.436.032.655.032a1.3 1.3 0 0 0 1.188-1.826L7.878 10H19v10h3v2H4v-2h3Zm7.843-6.753 1.315 1.506-4.095 3.574-2.22-1.937 1.315-1.507.905.79 2.78-2.426Z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const PollsFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={PollsFilledSvg} {...props} />
));
PollsFilled.displayName = 'PollsFilled';
export default PollsFilled;
