import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const WhatsappColoredSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      ref={ref}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path fill="#1BD741" d="M4 0h16a4 4 0 0 1 4 4v16a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4Z" />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="m3.491 20.29 1.177-4.174a8.347 8.347 0 0 1-1.155-4.245c0-4.621 3.763-8.38 8.389-8.38 4.626 0 8.39 3.759 8.39 8.38 0 4.62-3.764 8.38-8.39 8.38a8.386 8.386 0 0 1-4.101-1.068L3.49 20.29Zm4.53-2.632.257.157a6.958 6.958 0 0 0 3.624 1.014c3.841 0 6.966-3.121 6.966-6.958s-3.125-6.959-6.966-6.959-6.966 3.122-6.966 6.959c0 1.337.38 2.635 1.1 3.754l.173.27-.678 2.403 2.49-.64Zm1.173-9.584.543.029a.282.282 0 0 1 .244.169l.792 1.832a.276.276 0 0 1-.046.292l-.69.789a.204.204 0 0 0-.025.23c.907 1.73 2.562 2.415 3.056 2.586.085.03.178.002.232-.068l.722-.925a.284.284 0 0 1 .34-.082l1.838.837c.12.055.185.184.158.311l-.087.401c-.098.453-.37.853-.764 1.102-.498.315-1.143.47-1.962.24C11 15.107 9.56 13.44 8.789 12.4c-.772-1.041-1.07-2.083-.877-2.933.13-.57.55-1.008.814-1.234a.662.662 0 0 1 .468-.158Z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const WhatsappColored = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={WhatsappColoredSvg} {...props} />
));
WhatsappColored.displayName = 'WhatsappColored';
export default WhatsappColored;
