import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const TwitterSquareSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M24 2.4A2.4 2.4 0 0 0 21.6 0H2.4A2.4 2.4 0 0 0 0 2.4v19.2A2.4 2.4 0 0 0 2.4 24h19.2a2.4 2.4 0 0 0 2.4-2.4V2.4ZM11.784 8.732c0-1.84 1.543-3.332 3.447-3.332.991 0 1.887.405 2.515 1.053a7.056 7.056 0 0 0 2.19-.81 3.36 3.36 0 0 1-1.516 1.844 7.065 7.065 0 0 0 1.98-.525 6.89 6.89 0 0 1-1.72 1.725c.006.143.01.287.01.431 0 4.404-3.467 9.482-9.807 9.482-1.946 0-3.757-.552-5.283-1.497.27.031.545.047.823.047a7.064 7.064 0 0 0 4.28-1.427c-1.508-.027-2.78-.99-3.22-2.315a3.627 3.627 0 0 0 1.557-.057c-1.577-.306-2.765-1.652-2.765-3.267v-.042c.465.249.997.399 1.561.417-.924-.598-1.532-1.618-1.532-2.774 0-.611.17-1.184.465-1.676a9.9 9.9 0 0 0 7.105 3.482 3.26 3.26 0 0 1-.09-.759Z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const TwitterSquare = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={TwitterSquareSvg} {...props} />
));
TwitterSquare.displayName = 'TwitterSquare';
export default TwitterSquare;
