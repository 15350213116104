import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const ImportSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="m9.707 12.94-1.414 1.414L12 18.06l3.707-3.707-1.414-1.415L13 14.232V1.996h-2v12.236L9.707 12.94Z" />
      <path d="M2 9h5.5v2H4v8.5a.5.5 0 0 0 .5.5h15a.5.5 0 0 0 .5-.5V11h-4V9h6v10.5a2.5 2.5 0 0 1-2.5 2.5h-15A2.5 2.5 0 0 1 2 19.5V9Z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Import = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={ImportSvg} {...props} />
));
Import.displayName = 'Import';
export default Import;
