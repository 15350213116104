import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const CalendarFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M15 2v3h2V2h-2Z" />
      <path
        fillRule="evenodd"
        d="M2 6.5h20v13a2.5 2.5 0 0 1-2.5 2.5h-15A2.5 2.5 0 0 1 2 19.5v-13ZM3.5 12h17v-2h-17v2Z"
        clipRule="evenodd"
      />
      <path d="M7 5V2h2v3H7Z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const CalendarFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={CalendarFilledSvg} {...props} />
));
CalendarFilled.displayName = 'CalendarFilled';
export default CalendarFilled;
