import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const OverviewSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M3.983 8.121 12 2l8.017 6.121A2.5 2.5 0 0 1 21 10.108V21h-7v-6a2 2 0 1 0-4 0v6H3V10.108a2.5 2.5 0 0 1 .983-1.987ZM12 4.516l6.803 5.195a.5.5 0 0 1 .197.397V19h-3v-4a4 4 0 0 0-8 0v4H5v-8.892a.5.5 0 0 1 .197-.397L12 4.516Z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Overview = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={OverviewSvg} {...props} />
));
Overview.displayName = 'Overview';
export default Overview;
