export const getCurrentPathWithQueryString = (noTrimAuthPath?: boolean) => {
  // FIXME(AC-1130): This is a temporary fix for the issue where the auth path is not trimmed from the URL.
  const currentHref = location.href || '';
  const replacedOrigin = currentHref.replace(location.origin, '');
  if (noTrimAuthPath) {
    return replacedOrigin;
  }
  return `${replacedOrigin.replace(/^\/auth\/sign(in|out)\?next=/, '')}`;
};

/** @knipignore */
export const checkExternalUrl = (url: string) => {
  const params = new URLSearchParams(url);
  const nextUrl = params.get('next') || '/';

  if (nextUrl.includes('://')) return true;

  const normalized = new URL(nextUrl, 'http://dummy.com').pathname;
  const segments = normalized.split('/').filter(Boolean);

  if (segments[0] && segments[0].includes('.')) return true;
  return false;
};

export const getNextPath = (nextPathQuery = '?next=') => {
  const currentPath = getCurrentPathWithQueryString(true).replace(location.pathname, '');
  const replacedPath = currentPath.replace(nextPathQuery, '');

  if (
    !currentPath.includes(nextPathQuery) ||
    (currentPath.includes('http') && !currentPath.includes(location.origin)) ||
    checkExternalUrl(currentPath)
  ) {
    return '/';
  }
  return replacedPath;
};
