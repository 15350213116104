import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const GoogleDriveColoredSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      ref={ref}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="#4285F4"
        d="M1.074 15.25a2 2 0 0 0 .268 1.022l2.438 4.227A2 2 0 0 0 5.513 21.5h12.975a2 2 0 0 0 1.732-1l2.438-4.228c.183-.316.272-.67.268-1.022H1.074Z"
      />
      <path
        fill="#FBBC04"
        d="M19.495 21.227 8.559 2.282A1.999 1.999 0 0 1 9.582 2h4.835a2 2 0 0 1 1.732 1l6.509 11.273a2 2 0 0 1 0 1.999l-2.438 4.227a2 2 0 0 1-.724.728Z"
      />
      <path
        fill="#34A853"
        d="M15.442 2.282A1.998 1.998 0 0 0 14.418 2H9.582A2 2 0 0 0 7.85 3L1.342 14.273a2 2 0 0 0 0 1.999l2.438 4.227c.177.307.429.556.724.728L15.442 2.282Z"
      />
      <path fill="#EA4335" d="M22.926 15.25a2 2 0 0 1-.268 1.022l-2.438 4.227a2 2 0 0 1-.724.728l-3.451-5.977h6.88Z" />
      <path
        fill="#188038"
        d="M15.442 2.282A1.999 1.999 0 0 0 14.417 2H9.582c-.367 0-.72.1-1.024.282L12 8.244l3.442-5.962Z"
      />
      <path
        fill="#1967D2"
        d="M1.074 15.25a2 2 0 0 0 .268 1.022l2.438 4.227c.177.307.429.556.724.728l3.451-5.977h-6.88Z"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const GoogleDriveColored = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={GoogleDriveColoredSvg} {...props} />
));
GoogleDriveColored.displayName = 'GoogleDriveColored';
export default GoogleDriveColored;
