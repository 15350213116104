import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const BrDeveloperPortalSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#br-de-a)">
        <path d="M12.75 19.5V18h-1.5v1.5H3V21h18v-1.5h-8.25Z" />
        <path
          fillRule="evenodd"
          d="M3 3h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V3Zm16.013 7-4.015-3.511v1.993L16.734 10l-1.736 1.518v1.993L19.013 10ZM9.002 6.498 4.998 10l4.004 3.502v-1.975L7.256 10l1.746-1.527V6.498Zm4.498 0h-1.452L10.5 13.5h1.452L13.5 6.498Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="br-de-a">
          <path d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  )),
  { width: 24, height: 24 },
);
const BrDeveloperPortal = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={BrDeveloperPortalSvg} {...props} />
));
BrDeveloperPortal.displayName = 'BrDeveloperPortal';
export default BrDeveloperPortal;
