import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const ImageButtonSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M11.275 16.353 12 17l.725-.642.02-.018C15.309 14.054 17 12.547 17 10.697 17 9.188 15.79 8 14.25 8c-.87 0-1.705.397-2.25 1.025A3.018 3.018 0 0 0 9.75 8C8.21 8 7 9.187 7 10.697c0 1.853 1.697 3.363 4.269 5.65l.006.006Z" />
      <path fillRule="evenodd" d="M21 3H3v18h15a3 3 0 0 0 3-3V3ZM5 19V5h14v13a1 1 0 0 1-1 1H5Z" clipRule="evenodd" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const ImageButton = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={ImageButtonSvg} {...props} />
));
ImageButton.displayName = 'ImageButton';
export default ImageButton;
