import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const MetadataColoredSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 20 20"
      ref={ref}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <rect width={20} height={20} fill="#027D69" rx={4} />
      <path
        fill="#fff"
        d="M4.5 9.5v1.028H5c.049 0 .097-.002.145-.007A.5.5 0 0 1 5.5 11v2.528c0 .69.56 1.25 1.25 1.25H8v-1H6.75a.25.25 0 0 1-.25-.25V11c0-.377-.14-.722-.37-.986.23-.264.37-.608.37-.986V6.5a.25.25 0 0 1 .25-.25H8v-1H6.75c-.69 0-1.25.56-1.25 1.25v2.528a.5.5 0 0 1-.355.479A1.52 1.52 0 0 0 5 9.5h-.5ZM14.5 9.028a.5.5 0 0 0 .355.479c.048-.005.096-.007.145-.007h.5v1.028H15c-.049 0-.097-.002-.145-.007A.5.5 0 0 0 14.5 11v2.528c0 .69-.56 1.25-1.25 1.25h-1v-1h1a.25.25 0 0 0 .25-.25V11c0-.377.14-.722.37-.986a1.494 1.494 0 0 1-.37-.986V6.5a.25.25 0 0 0-.25-.25H12v-1h1.25c.69 0 1.25.56 1.25 1.25v2.528ZM10 10.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Z"
      />
      <path fill="#fff" d="M12.5 10a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0ZM8 10.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Z" />
    </svg>
  )),
  { width: 20, height: 20 },
);
const MetadataColored = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={MetadataColoredSvg} {...props} />
));
MetadataColored.displayName = 'MetadataColored';
export default MetadataColored;
