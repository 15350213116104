import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const ClaudeColoredSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 20"
      ref={ref}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#claud-a)">
        <path
          fill="#0D0D0D"
          d="M11.54 4H9.09l4.46 12H16L11.54 4ZM4.46 4 0 16h2.5l.9-2.52h4.68L8.99 16h2.5L7.02 4H4.46Zm-.24 7.25 1.52-4.22 1.53 4.22H4.22Z"
        />
      </g>
      <defs>
        <clipPath id="claud-a">
          <path fill="#fff" d="M0 0H16V12H0z" transform="translate(0 4)" />
        </clipPath>
      </defs>
    </svg>
  )),
  { width: 16, height: 20 },
);
const ClaudeColored = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={ClaudeColoredSvg} {...props} />
));
ClaudeColored.displayName = 'ClaudeColored';
export default ClaudeColored;
