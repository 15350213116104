import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const DocumentSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M8 8.997h4v-2H8v2ZM8 12.997v-2h8v2H8ZM8 14.997v2h8v-2H8Z" />
      <path
        fillRule="evenodd"
        d="M14.51 2H4v17.5A2.5 2.5 0 0 0 6.5 22h11a2.5 2.5 0 0 0 2.5-2.5V7.657L14.51 2ZM6 19.5V4h7.665L18 8.468V19.5a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5Z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Document = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={DocumentSvg} {...props} />
));
Document.displayName = 'Document';
export default Document;
