import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const ListSortingSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M12.707 8.293 7 2.586 1.293 8.293l1.414 1.414L6 6.414V21h2V6.414l3.293 3.293 1.414-1.414ZM16 17.586V3h2v14.586l3.293-3.293 1.414 1.414L17 21.414l-5.707-5.707 1.414-1.414L16 17.586Z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const ListSorting = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={ListSortingSvg} {...props} />
));
ListSorting.displayName = 'ListSorting';
export default ListSorting;
