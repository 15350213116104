import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const SortingTwotoneSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path id="Color_01" d="m8 10 4-6 4 6H8Z" />
      <path id="Color_02" d="m8 14 4 6 4-6H8Z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const SortingTwotone = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={SortingTwotoneSvg} {...props} />
));
SortingTwotone.displayName = 'SortingTwotone';
export default SortingTwotone;
