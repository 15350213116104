import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const CloudDownloadFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M7.023 4.662C7.787 3.112 9.347 2 11.159 2c1.784 0 3.274 1.047 4.055 2.503a3.557 3.557 0 0 1 3.649 2.682 3.89 3.89 0 0 1 3.088 4.425c-.159 1.062-.664 1.957-1.49 2.573-.812.604-1.85.877-2.988.877H15V9H9v6.06H7.057C4.236 15.06 2 12.696 2 9.86c0-2.747 2.245-5.178 5.023-5.198Z" />
      <path d="m13 17.896 1.543-1.543 1.414 1.415L12 21.725l-3.957-3.957 1.414-1.415L11 17.896V11h2v6.896Z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const CloudDownloadFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={CloudDownloadFilledSvg} {...props} />
));
CloudDownloadFilled.displayName = 'CloudDownloadFilled';
export default CloudDownloadFilled;
