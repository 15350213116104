import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const UsersFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M9 12.5a8 8 0 0 1 8 8v.5H1v-.5a8 8 0 0 1 8-8ZM9 11a4 4 0 1 1 0-8 4 4 0 0 1 0 8Z" />
      <path d="M9 11a4 4 0 1 1 0-8 4 4 0 0 1 0 8ZM19 21v-.5a9.985 9.985 0 0 0-3.999-8h.003a8 8 0 0 1 8 8v.5H19ZM13.667 10.772a4 4 0 1 0 0-7.543A5.975 5.975 0 0 1 15 7c0 1.429-.5 2.741-1.333 3.772Z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const UsersFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={UsersFilledSvg} {...props} />
));
UsersFilled.displayName = 'UsersFilled';
export default UsersFilled;
