import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const SignAtSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M5.151 1.973A6.667 6.667 0 0 1 14.667 8v.6a2.467 2.467 0 0 1-4.428 1.495 3.067 3.067 0 1 1-.505-4.626v-.536h1.333V8.6a1.133 1.133 0 0 0 2.266 0V8a5.334 5.334 0 1 0-5.43 5.332h3.164v1.334H7.885A6.667 6.667 0 0 1 5.151 1.973ZM9.733 8a1.733 1.733 0 1 0-3.466 0 1.733 1.733 0 0 0 3.466 0Z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 16, height: 16 },
);
const SignAt = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={SignAtSvg} {...props} />
));
SignAt.displayName = 'SignAt';
export default SignAt;
