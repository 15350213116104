import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const OpenChannelsFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM14.5 4.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0ZM7 12a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0ZM22 12a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0ZM14.5 19.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0ZM7.5 6.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0ZM16.5 17.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0ZM19 6.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0ZM5 17.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const OpenChannelsFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={OpenChannelsFilledSvg} {...props} />
));
OpenChannelsFilled.displayName = 'OpenChannelsFilled';
export default OpenChannelsFilled;
