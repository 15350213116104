import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const BanSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="m19.586 21 2 2L23 21.586 2.418 1.004 1.004 2.418l10.128 10.128A8.001 8.001 0 0 0 4 20.5h-.004v.5h2v-.5H6a6 6 0 0 1 7.207-5.879l4.671 4.672c.08.39.122.793.122 1.207v.5h1.586ZM16 7a3.994 3.994 0 0 1-1.585 3.189l-1.441-1.442a2 2 0 1 0-2.721-2.721L8.81 4.585A4 4 0 0 1 16 7Z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Ban = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={BanSvg} {...props} />
));
Ban.displayName = 'Ban';
export default Ban;
