import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const BrPtoSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#br-pt-a)">
        <path
          fillRule="evenodd"
          d="M2.003 8h20v11a2 2 0 0 1-2 2h-16a2 2 0 0 1-2-2V8Zm9 3h1.5v3.5h3.5V16h-5v-5Z"
          clipRule="evenodd"
        />
        <path d="M6.003 3h1.5v1h8.996V3h1.5v1h1.254a2.75 2.75 0 0 1 2.75 2.75V7h-1.5v-.25c0-.69-.56-1.25-1.25-1.25h-14.5c-.69 0-1.25.56-1.25 1.25V7h-1.5v-.25A2.75 2.75 0 0 1 4.753 4h1.25V3Z" />
      </g>
      <defs>
        <clipPath id="br-pt-a">
          <path d="M0 0H24V24H0z" transform="translate(.003)" />
        </clipPath>
      </defs>
    </svg>
  )),
  { width: 25, height: 24 },
);
const BrPto = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={BrPtoSvg} {...props} />
));
BrPto.displayName = 'BrPto';
export default BrPto;
