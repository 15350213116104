import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const BrBlogSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#br-bl-a)">
        <path d="M4.004 5v14a2 2 0 0 0 2 2h14V8H7.254a1.75 1.75 0 1 1 0-3.5h12.75V3h-14a2 2 0 0 0-2 2Z" />
        <path
          fillRule="evenodd"
          d="M6.504 6.25a.75.75 0 0 1 .75-.75h12.75V7H7.254a.75.75 0 0 1-.75-.75Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="br-bl-a">
          <path d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  )),
  { width: 24, height: 24 },
);
const BrBlog = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={BrBlogSvg} {...props} />
));
BrBlog.displayName = 'BrBlog';
export default BrBlog;
