import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const GoogleColoredSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      ref={ref}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="#4285F4"
        d="M22 12.225c0-.833-.068-1.441-.215-2.072h-9.59v3.762h5.63c-.114.934-.727 2.342-2.089 3.288l-.02.126 3.033 2.33.21.021C20.889 17.912 22 15.311 22 12.225Z"
      />
      <path
        fill="#34A853"
        d="M12.196 22.135c2.757 0 5.072-.9 6.763-2.455l-3.223-2.477c-.862.596-2.02 1.013-3.54 1.013-2.701 0-4.994-1.768-5.811-4.212l-.12.01-3.152 2.422-.042.113a10.214 10.214 0 0 0 9.125 5.586Z"
      />
      <path
        fill="#FBBC05"
        d="M6.385 14.004A6.197 6.197 0 0 1 6.045 12c0-.698.124-1.374.328-2.005l-.005-.134-3.193-2.46-.104.05A10.081 10.081 0 0 0 1.98 12c0 1.633.398 3.175 1.09 4.55l3.314-2.546Z"
      />
      <path
        fill="#EB4335"
        d="M12.196 5.784c1.917 0 3.211.822 3.949 1.509L19.027 4.5c-1.77-1.633-4.074-2.635-6.831-2.635A10.214 10.214 0 0 0 3.07 7.45l3.303 2.545c.828-2.443 3.12-4.211 5.822-4.211Z"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const GoogleColored = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={GoogleColoredSvg} {...props} />
));
GoogleColored.displayName = 'GoogleColored';
export default GoogleColored;
