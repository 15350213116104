import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const FacebookSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M17.2 3h-3.054c-2.812 0-5.091 2.09-5.091 4.667v2.8H6V14.2h3.055v7.467h4.072V14.2h3.055l1.018-3.733h-4.073v-2.8c0-.516.456-.934 1.018-.934H17.2V3Z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Facebook = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={FacebookSvg} {...props} />
));
Facebook.displayName = 'Facebook';
export default Facebook;
