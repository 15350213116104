import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const NoAutomationOnColoredSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 20 20"
      ref={ref}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="#0D0D0D"
        fillRule="evenodd"
        d="M10 2.499H7.359a2.501 2.501 0 1 0 0 1.666h2.643V2.5Zm-5 0a.833.833 0 1 0 0 1.666A.833.833 0 0 0 5 2.5Z"
        clipRule="evenodd"
      />
      <path
        fill="#0D0D0D"
        d="M17.5 6.665c0 1.411-1.092 2.5-2.374 2.5H13.75v1.667h1.375c2.261 0 4.041-1.896 4.041-4.167h-1.666Z"
      />
      <path
        fill="#0D0D0D"
        fillRule="evenodd"
        d="M.834 13.332c0-2.27 1.78-4.167 4.042-4.167H6.25v1.667H4.876c-1.282 0-2.375 1.089-2.375 2.5s1.093 2.5 2.375 2.5h7.767a2.5 2.5 0 1 1 0 1.667H4.876c-2.262 0-4.042-1.896-4.042-4.167Zm13.333 3.333a.833.833 0 1 1 1.667 0 .833.833 0 0 1-1.667 0Z"
        clipRule="evenodd"
      />
      <path
        fill="#0D0D0D"
        d="m6.911 8.088 1.911 1.91-1.91 1.912 1.178 1.178 1.91-1.91 1.912 1.91 1.178-1.178-1.91-1.911 1.91-1.911-1.179-1.179-1.91 1.911L8.09 6.91 6.911 8.087Z"
      />
      <path fill="#D9352C" d="M16.25 4.999V.832h.834l1.667 2.778V.832h.833v4.167h-.833L17.084 2.22v2.778h-.833Z" />
      <path
        fill="#D9352C"
        fillRule="evenodd"
        d="M12.084.832h3.333v4.167h-3.333V.832Zm.833.833v2.5h1.667v-2.5h-1.667Z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 20, height: 20 },
);
const NoAutomationOnColored = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={NoAutomationOnColoredSvg} {...props} />
));
NoAutomationOnColored.displayName = 'NoAutomationOnColored';
export default NoAutomationOnColored;
