import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const LongTextSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M14.667 7.333H1.333V6h13.334v1.333ZM1.333 10h13.334V8.667H1.333V10ZM10 11.333H1.333v1.334H10v-1.334ZM14.667 4.667H1.333V3.333h13.334v1.334Z" />
    </svg>
  )),
  { width: 16, height: 16 },
);
const LongText = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={LongTextSvg} {...props} />
));
LongText.displayName = 'LongText';
export default LongText;
