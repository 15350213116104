import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const GiftSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M4.34 7a3.5 3.5 0 0 1 3.162-5c2.306 0 3.693 1.662 4.439 3.018a8.3 8.3 0 0 1 .06.113l.062-.113C12.81 3.662 14.196 2 16.502 2a3.5 3.5 0 0 1 3.162 5H22v12.5a2.5 2.5 0 0 1-2.5 2.5H4.504a2.5 2.5 0 0 1-2.5-2.5V7H4.34ZM6.44 4.44A1.5 1.5 0 0 1 7.502 4c1.194 0 2.057.838 2.686 1.982.191.347.346.699.468 1.018H7.502a1.5 1.5 0 0 1-1.06-2.56ZM16.501 7a1.5 1.5 0 0 0 0-3c-1.193 0-2.056.838-2.685 1.982A8.208 8.208 0 0 0 13.348 7h3.154ZM4.005 19.5v-4h6.998V20H4.504a.5.5 0 0 1-.5-.5Zm6.998-6H4.004V9h6.998v4.5Zm2 6.5v-4.5H20v4a.5.5 0 0 1-.5.5h-6.498Zm0-6.5H20V9h-6.998v4.5Z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Gift = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={GiftSvg} {...props} />
));
Gift.displayName = 'Gift';
export default Gift;
