import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const KakaoFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M.34 10.918C.34 5.856 5.56 1.752 12 1.752s11.66 4.105 11.66 9.166c0 5.062-5.22 9.164-11.66 9.164-.74 0-1.462-.054-2.164-.157a12865.096 12865.096 0 0 0-4.487 3.032c-.258.168-.648.177-.49-.453.129-.508.767-2.884 1.011-3.788C2.55 17.101.34 14.213.34 10.918Zm4.358-1.432h1.076s.005 2.938 0 3.623c0 .5 1.119.506 1.117.008l.004-3.592s.361.002 1.07.002c.669 0 .669-1.046 0-1.046-.754 0-3.273-.022-3.273-.022-.632 0-.63 1.027 0 1.027h.006Zm5.15.19.598 1.865H9.198l.65-1.864Zm-.671-.873c-.134.294-.447 1.143-.763 2.003-.308.836-.62 1.683-.775 2.04-.229.525.76.953.989.429l.215-.748h1.968s-.067.031.194.716c.21.556 1.233.171 1.022-.385-.088-.231-.24-.64-.416-1.112-.425-1.142-.988-2.656-1.123-2.943-.087-.186-.403-.321-.71-.321-.252 0-.499.092-.603.321h.002Zm3.235 4.1s-.006-2.998-.006-3.929v-.002c-.002-.326.292-.49.582-.49s.582.162.582.488c.002.675-.002 3.449-.002 3.449s.924.006 1.43.006c.505 0 .507 1.07.002 1.07-.252 0-.659 0-1.069.002h-.001l-1.09.003c-.515 0-.428-.596-.428-.596Zm3.227-4.076c.009.307-.003 2.466-.01 3.637l-.004.734c0 .432 1.106.43 1.106 0v-1.453l.323-.259 1.42 1.924c.286.377 1.188-.304.902-.68l-1.506-1.972s1.022-1.099 1.425-1.497c.235-.232-.494-.962-.729-.729-.227.224-1.83 1.82-1.83 1.82s.014-1.001 0-1.555c-.005-.21-.264-.312-.53-.312-.285 0-.575.116-.569.342h.002Z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const KakaoFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={KakaoFilledSvg} {...props} />
));
KakaoFilled.displayName = 'KakaoFilled';
export default KakaoFilled;
