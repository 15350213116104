import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const AnalyticsSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M12 7c0 .723-.256 1.387-.682 1.905l3.087 5.154a3.018 3.018 0 0 1 .966-.036l2.47-4.94a3 3 0 1 1 1.789.895l-2.47 4.94a3 3 0 1 1-4.395.083h-.127L9.606 9.938a3.014 3.014 0 0 1-.977.038l-2.47 4.94a3 3 0 1 1-1.789-.895l2.47-4.94A3 3 0 1 1 12 7ZM9 8a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm6 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM5 17a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM21 7a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Analytics = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={AnalyticsSvg} {...props} />
));
Analytics.displayName = 'Analytics';
export default Analytics;
