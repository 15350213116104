import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const BrToggleSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M10.389 15.894c-1.071 1.07-2.37 1.606-3.894 1.606-1.525 0-2.822-.535-3.893-1.606C1.53 14.823.995 13.524.995 12c0-1.525.536-2.823 1.607-3.894C3.672 7.036 4.97 6.5 6.495 6.5s2.823.535 3.894 1.606c1.07 1.071 1.606 2.37 1.606 3.894 0 1.525-.535 2.823-1.606 3.894Z" />
      <path d="M11.648 7.25c.436.467.794.967 1.075 1.5h5.522a3.25 3.25 0 1 1 0 6.5h-5.522a6.991 6.991 0 0 1-1.075 1.5h6.597a4.75 4.75 0 1 0 0-9.5h-6.597Z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const BrToggle = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={BrToggleSvg} {...props} />
));
BrToggle.displayName = 'BrToggle';
export default BrToggle;
