import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const GenerateTextFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M22 7H2V5h20v2ZM12 13H2v-2h10v2ZM2 19h8.5v-2H2v2ZM19.745 13.255 17.5 8.764l-2.246 4.49-4.49 2.246 4.49 2.245 2.246 4.491 2.245-4.49 4.49-2.246-4.49-2.245Z" />
    </svg>
  )),
  { width: 25, height: 24 },
);
const GenerateTextFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={GenerateTextFilledSvg} {...props} />
));
GenerateTextFilled.displayName = 'GenerateTextFilled';
export default GenerateTextFilled;
