import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const BrMarketingSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#br-ma-a)">
        <path
          fillRule="evenodd"
          d="M12 2c1.944 0 3.76.555 5.295 1.515l-1.831 1.831a7.5 7.5 0 1 0 3.185 3.18l1.83-1.83A9.953 9.953 0 0 1 22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2Zm0 4.5c.688 0 1.346.126 1.953.357l-2.15 2.15a3 3 0 1 0 3.192 3.174l2.144-2.144A5.5 5.5 0 1 1 12 6.5Z"
          clipRule="evenodd"
        />
        <path d="M13.448 11.606A1.503 1.503 0 0 1 12 13.5a1.5 1.5 0 1 1 .393-2.948L20.447 2.5 21.5 3.554l-8.052 8.052Z" />
      </g>
      <defs>
        <clipPath id="br-ma-a">
          <path d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  )),
  { width: 24, height: 24 },
);
const BrMarketing = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={BrMarketingSvg} {...props} />
));
BrMarketing.displayName = 'BrMarketing';
export default BrMarketing;
