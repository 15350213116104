import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const GooglePlayColoredSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      ref={ref}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="url(#googl-a)"
        d="M1.716.442a1.802 1.802 0 0 0-.42 1.285v20.291c-.032.466.118.927.42 1.284l.065.065 11.365-11.365v-.26L1.781.368l-.065.075Z"
      />
      <path
        fill="url(#googl-b)"
        d="m16.934 15.801-3.789-3.799v-.259l3.789-3.8.086.055 4.49 2.547a1.392 1.392 0 0 1 0 2.655l-4.49 2.547-.086.054Z"
      />
      <path fill="url(#googl-c)" d="m17.02 15.747-3.874-3.874-11.43 11.43a1.49 1.49 0 0 0 1.91.064l13.394-7.62Z" />
      <path fill="url(#googl-d)" d="M17.02 7.998 3.626.378a1.49 1.49 0 0 0-1.91.065l11.43 11.43 3.874-3.875Z" />
      <path
        fill="#0D0D0D"
        d="M16.934 15.66 3.626 23.217a1.544 1.544 0 0 1-1.845 0l-.065.065.065.075c.546.412 1.299.412 1.845 0l13.394-7.62-.086-.075Z"
        opacity={0.2}
      />
      <path
        fill="#0D0D0D"
        d="M1.716 23.173c-.3-.358-.45-.818-.421-1.284v.13c-.032.466.12.926.42 1.284l.066-.065-.065-.065ZM21.51 13.06l-4.575 2.601.086.086 4.49-2.547c.54-.232.909-.742.96-1.327-.113.525-.47.966-.96 1.187Z"
        opacity={0.12}
      />
      <path
        fill="#fff"
        d="m3.626.518 17.885 10.167c.49.221.847.662.96 1.188a1.608 1.608 0 0 0-.96-1.328L3.625.378C2.342-.345 1.295.259 1.295 1.727v.14C1.295.39 2.342-.216 3.626.518Z"
        opacity={0.25}
      />
      <defs>
        <linearGradient id="googl-a" x1={8.176} x2={-10.029} y1={-13.43} y2={-8.597} gradientUnits="userSpaceOnUse">
          <stop stopColor="#00A0FF" />
          <stop offset={0.01} stopColor="#00A1FF" />
          <stop offset={0.26} stopColor="#00BEFF" />
          <stop offset={0.51} stopColor="#00D2FF" />
          <stop offset={0.76} stopColor="#00DFFF" />
          <stop offset={1} stopColor="#00E3FF" />
        </linearGradient>
        <linearGradient id="googl-b" x1={23.183} x2={0.982} y1={2.531} y2={2.531} gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFE000" />
          <stop offset={0.41} stopColor="#FFBD00" />
          <stop offset={0.78} stopColor="orange" />
          <stop offset={1} stopColor="#FF9C00" />
        </linearGradient>
        <linearGradient id="googl-c" x1={1.391} x2={-13.249} y1={5.923} y2={30.509} gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF3A44" />
          <stop offset={1} stopColor="#C31162" />
        </linearGradient>
        <linearGradient id="googl-d" x1={-7.208} x2={-0.668} y1={-2.727} y2={8.257} gradientUnits="userSpaceOnUse">
          <stop stopColor="#32A071" />
          <stop offset={0.07} stopColor="#2DA771" />
          <stop offset={0.48} stopColor="#15CF74" />
          <stop offset={0.8} stopColor="#06E775" />
          <stop offset={1} stopColor="#00F076" />
        </linearGradient>
      </defs>
    </svg>
  )),
  { width: 24, height: 24 },
);
const GooglePlayColored = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={GooglePlayColoredSvg} {...props} />
));
GooglePlayColored.displayName = 'GooglePlayColored';
export default GooglePlayColored;
