import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const BrAnnouncementsSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#br-an-a)">
        <path fillRule="evenodd" d="M7.504 22v-6.5h1.5V22h-1.5Z" clipRule="evenodd" />
        <path d="m22 2-8.5 4h-6v8h6l8.5 3.5V2ZM2 10a4 4 0 0 0 4 4V6a4 4 0 0 0-4 4Z" />
      </g>
      <defs>
        <clipPath id="br-an-a">
          <path d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  )),
  { width: 24, height: 24 },
);
const BrAnnouncements = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={BrAnnouncementsSvg} {...props} />
));
BrAnnouncements.displayName = 'BrAnnouncements';
export default BrAnnouncements;
