import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const UserFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M12 11a4 4 0 1 1 0-8 4 4 0 0 1 0 8ZM12 12.5a8 8 0 0 1 8 8v.5H3.996v-.5H4a8 8 0 0 1 8-8Z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const UserFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={UserFilledSvg} {...props} />
));
UserFilled.displayName = 'UserFilled';
export default UserFilled;
