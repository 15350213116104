import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const MergeSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M10.79 2.333a.875.875 0 1 1-.875.877v-.003c.001-.483.393-.874.875-.874Zm0 2.917a2.043 2.043 0 0 1-1.957-1.458H7.29v6.416h1.543a2.043 2.043 0 1 1 0 1.167h-2.71V7.583H5.116a2.042 2.042 0 1 1 .087-1.166h.922V2.625h2.71A2.043 2.043 0 1 1 10.79 5.25Zm-.875 5.543v-.003a.875.875 0 1 1 0 .003ZM3.207 5.979a.875.875 0 1 1 0 1.75.875.875 0 0 1 0-1.75Z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 14, height: 14 },
);
const Merge = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={MergeSvg} {...props} />
));
Merge.displayName = 'Merge';
export default Merge;
