import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const SettingsFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M15.81 5.538a7.454 7.454 0 0 0-1.935-.802V2h-3.75v2.736a7.455 7.455 0 0 0-1.935.802L6.255 3.603 3.603 6.255 5.538 8.19a7.455 7.455 0 0 0-.802 1.935H2v3.75h2.736c.177.688.45 1.338.802 1.935l-1.935 1.935 2.652 2.652 1.935-1.935a7.453 7.453 0 0 0 1.935.802V22h3.75v-2.736a7.452 7.452 0 0 0 1.935-.802l1.935 1.935 2.652-2.652-1.935-1.935a7.452 7.452 0 0 0 .802-1.935H22v-3.75h-2.736a7.453 7.453 0 0 0-.802-1.935l1.935-1.935-2.652-2.652-1.935 1.935ZM12 14.222a2.222 2.222 0 1 1 0-4.444 2.222 2.222 0 0 1 0 4.444Z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const SettingsFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={SettingsFilledSvg} {...props} />
));
SettingsFilled.displayName = 'SettingsFilled';
export default SettingsFilled;
