import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const TextUnderlineSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M4 2v6.667a4 4 0 0 0 8 0V2h-1.333v6.667a2.667 2.667 0 0 1-5.333 0V2H4.001ZM12.667 14.667v-1.334H3.334v1.334h9.333Z" />
    </svg>
  )),
  { width: 16, height: 16 },
);
const TextUnderline = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={TextUnderlineSvg} {...props} />
));
TextUnderline.displayName = 'TextUnderline';
export default TextUnderline;
