import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const TimelineSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M10.605 2.098a10 10 0 1 1-8.038 13.225l1.886-.665a8 8 0 1 0 1.893-8.32l-.015.016L4.529 8H7v2H1V4h2v2.688l1.95-1.781a10 10 0 0 1 5.655-2.81Z" />
      <path d="M12.485 6.244h-2v6.618l4.553 2.277.895-1.79-3.448-1.723V6.244Z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Timeline = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={TimelineSvg} {...props} />
));
Timeline.displayName = 'Timeline';
export default Timeline;
