import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const WebApplicationSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M19.5 2H2v12.5A2.5 2.5 0 0 0 4.5 17H11v3H5v2h14v-2h-6v-3h6.5a2.5 2.5 0 0 0 2.5-2.5v-10A2.5 2.5 0 0 0 19.5 2ZM4 14.5V13h16v1.5a.5.5 0 0 1-.5.5h-15a.5.5 0 0 1-.5-.5ZM20 11H4V4h15.5a.5.5 0 0 1 .5.5V11Z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const WebApplication = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={WebApplicationSvg} {...props} />
));
WebApplication.displayName = 'WebApplication';
export default WebApplication;
