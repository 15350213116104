import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const BrAlreadyOnItSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g fillRule="evenodd" clipPath="url(#br-al-a)" clipRule="evenodd">
        <path d="M23.003 7.25h-11.5v-1.5h11.5v1.5ZM23.003 18.25h-11.5v-1.5h11.5v1.5Z" />
        <path d="M10.003 22h-9v-9h7a2 2 0 0 1 2 2v7Zm-4.865-2.25 3.115-3.525-1.109-.975-2.095 2.371-1.273-1.218-1.023 1.064 2.385 2.283ZM10.003 11h-9V2h7a2 2 0 0 1 2 2v7ZM5.138 8.75l3.115-3.525-1.109-.975L5.05 6.621 3.776 5.403 2.753 6.467 5.138 8.75Z" />
      </g>
      <defs>
        <clipPath id="br-al-a">
          <path d="M0 0H24V24H0z" transform="translate(.003)" />
        </clipPath>
      </defs>
    </svg>
  )),
  { width: 25, height: 24 },
);
const BrAlreadyOnIt = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={BrAlreadyOnItSvg} {...props} />
));
BrAlreadyOnIt.displayName = 'BrAlreadyOnIt';
export default BrAlreadyOnIt;
