import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const UikitHorizontalSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 69 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M10.442 7.128c0-2.06-1.658-3.729-3.703-3.729H3.036v3.73c0 2.058 1.658 3.728 3.703 3.728s3.703-1.67 3.703-3.729Zm45.473-2.862c-.924 0-1.691.777-1.691 1.694 0 .918.767 1.713 1.691 1.713.925 0 1.692-.776 1.692-1.713 0-.936-.767-1.694-1.692-1.694Zm-2.752.492Zm0 0h-3.705l-4.583 5.486V4.758h-2.936v13.557h2.936v-6.673l5.297 6.673h3.849l-6.07-7.392 5.212-6.165Zm-20.508 8.144c0 1.559-1.092 2.716-2.559 2.716s-2.579-1.159-2.579-2.716V4.758H24.58v8.144c0 3.216 2.342 5.593 5.516 5.593 3.173 0 5.495-2.377 5.495-5.593V4.758h-2.937v8.144Zm7.579-8.144h-2.937v13.557h2.937V4.758Zm17.08 3.748h-2.798v9.809h2.797v-9.81Zm7.796 0v2.676h-2.4v3.097c0 1.059.715 1.518 1.686 1.518.238 0 .495-.02.714-.08v2.597c-.377.1-.953.16-1.35.16-2.3 0-3.848-1.518-3.848-4.155v-3.136h-1.806V8.506h1.806V5.929h2.797v2.577h2.4ZM3.036 12.213c0 4.119 3.316 7.458 7.406 7.458v-7.458H3.036Zm16.832-.678c0 4.493-3.617 8.136-8.08 8.136V3.399c4.463 0 8.08 3.643 8.08 8.136Z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 69, height: 24 },
);
const UikitHorizontal = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={UikitHorizontalSvg} {...props} />
));
UikitHorizontal.displayName = 'UikitHorizontal';
export default UikitHorizontal;
