import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const AlignCenterSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M1.666 2.5h16.667v1.667H1.666V2.5ZM4.166 5.833h10.833V7.5H4.166V5.833ZM18.333 9.167H1.666v1.666h16.667V9.167ZM4.166 12.5h10.833v1.667H4.166V12.5ZM18.333 15.833H1.666V17.5h16.667v-1.667Z" />
    </svg>
  )),
  { width: 20, height: 20 },
);
const AlignCenter = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={AlignCenterSvg} {...props} />
));
AlignCenter.displayName = 'AlignCenter';
export default AlignCenter;
