import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const LayoutListViewSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M12 4h10v13.5a2.5 2.5 0 0 1-2.5 2.5H12V4Zm2 2v12h5.5a.5.5 0 0 0 .5-.5V6h-6ZM10 7H2V5h8v2ZM10 13H2v-2h8v2ZM10 19H2v-2h8v2Z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const LayoutListView = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={LayoutListViewSvg} {...props} />
));
LayoutListView.displayName = 'LayoutListView';
export default LayoutListView;
