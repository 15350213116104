import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const ClearSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M12 10h3.5v2h-9v-2H10V2h2v8Z" />
      <path
        fillRule="evenodd"
        d="M16.343 22H1.998l1.713-6.626A2.5 2.5 0 0 1 6.13 13.5h8.37a2.5 2.5 0 0 1 2.485 2.779L16.343 22Zm-7.57-2 .677-3H7.507l-.738 3H4.581l1.066-4.125a.5.5 0 0 1 .484-.375h8.37a.5.5 0 0 1 .497.556L14.555 20H8.773Z"
        clipRule="evenodd"
      />
      <path d="M22 12h-5v-2h5v2ZM18.5 16H22v-2h-3.5v2ZM22 20h-4v-2h4v2Z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Clear = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={ClearSvg} {...props} />
));
Clear.displayName = 'Clear';
export default Clear;
