import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const CustomersFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M22 19h-6.94L12 22l-2.94-3H2V3h18a2 2 0 0 1 2 2v14Zm-10-7.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Zm0 .5a4 4 0 0 0-4 4h8a4 4 0 0 0-4-4Z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const CustomersFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={CustomersFilledSvg} {...props} />
));
CustomersFilled.displayName = 'CustomersFilled';
export default CustomersFilled;
