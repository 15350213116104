import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const ScrollToTopSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M20 6H4V4h16v2ZM5.293 13.793l1.414 1.414L11 10.914V21h2V10.914l4.293 4.293 1.414-1.414L12 7.086l-6.707 6.707Z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const ScrollToTop = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={ScrollToTopSvg} {...props} />
));
ScrollToTop.displayName = 'ScrollToTop';
export default ScrollToTop;
