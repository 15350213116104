import { memo } from 'react';

import styled, { css } from 'styled-components';

import cssVariables from '@feather/theme/cssVariables';
import type { SpinnerFullProps } from '@feather/types';
import { ZIndexes } from '@feather/zIndexes';

import { default as Spinner } from './spinnerDefault';

const SpinnerWrapper = styled.div<{ zIndex?: number }>`
  position: absolute;
  z-index: ${({ zIndex = ZIndexes.spinnerFullDefault }) => zIndex};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
`;

const SpinnerColumn = styled.div``;

const SpinnerCover = styled.div<SpinnerFullProps>`
  position: ${(props) => (props.transparent ? 'absolute' : 'relative')};
  ${(props) =>
    props.transparent
      ? css`
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        `
      : css`
          width: 100%;
          height: 100%;
        `}
  min-height: 200px;

  ${SpinnerColumn} {
    flex-grow: 1;
    flex-basis: 33.33%;
    ${(props) =>
      props.transparent &&
      css`
        background: rgba(255, 255, 255, 0.5);
      `};
  }

  .spinner-container {
    flex-grow: 1;
    flex-basis: 33.33%;
    padding-bottom: 5%;
    align-items: center;
    justify-content: center;
    display: flex;
    ${(props) =>
      props.transparent &&
      css`
        background: rgba(255, 255, 255, 0.5);
      `};
  }

  ${(props) => props.styles}
`;

const SPINNER_SIZE: Record<NonNullable<SpinnerFullProps['size']>, number> = {
  medium: 26.67,
  large: 53.33,
};

export const SpinnerFull = memo(({ size = 'large', zIndex, ...props }: SpinnerFullProps) => {
  return (
    <SpinnerCover role="progressbar" {...props}>
      <SpinnerWrapper zIndex={zIndex}>
        <SpinnerColumn />
        <div className="spinner-container">
          <Spinner stroke={cssVariables('border-primary')} size={SPINNER_SIZE[size] ?? 53.33} />
        </div>
        <SpinnerColumn />
      </SpinnerWrapper>
    </SpinnerCover>
  );
});
