import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const DotnetColoredSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      ref={ref}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="#632EA0"
        fillRule="evenodd"
        d="M12.145.04C18.67.04 23.96 5.33 23.96 11.855c0 6.526-5.29 11.816-11.816 11.816C5.62 23.67.33 18.38.33 11.855.33 5.33 5.62.04 12.145.04Z"
        clipRule="evenodd"
      />
      <path
        fill="#753BB7"
        fillRule="evenodd"
        d="M3.976 3.254c-4.898 4.364-5.33 11.872-.966 16.77s11.872 5.33 16.77.965c.34-.303.663-.625.966-.965L3.976 3.254Z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M2.846 15.596a.389.389 0 0 1-.288-.12.393.393 0 0 1-.118-.285c0-.107.042-.21.118-.286a.396.396 0 0 1 .575 0 .395.395 0 0 1 0 .571.396.396 0 0 1-.287.12ZM10.983 15.596H9.967l-3.635-5.515a2.278 2.278 0 0 1-.225-.433h-.031c.034.315.047.632.038.949v5h-.826V8.68h1.075L9.9 14.098c.147.225.242.38.287.463h.02a7.189 7.189 0 0 1-.051-1.017V8.681h.826v6.915ZM15.86 15.596h-3.657V8.681h3.488v.732h-2.666v2.299h2.484v.728h-2.484v2.398h2.84l-.005.758ZM21.153 9.413H19.11v6.183h-.82V9.413h-2.02v-.732h4.882v.732Z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const DotnetColored = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={DotnetColoredSvg} {...props} />
));
DotnetColored.displayName = 'DotnetColored';
export default DotnetColored;
