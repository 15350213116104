import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const IframeColoredSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      ref={ref}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="#AD5CE6"
        fillRule="evenodd"
        d="M4 0h16a4 4 0 0 1 4 4v16a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4Z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M5 6h10v12H5a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1Z"
        clipRule="evenodd"
        opacity={0.4}
      />
      <path fill="#fff" fillRule="evenodd" d="M19 6h-4v12h4a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1Z" clipRule="evenodd" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const IframeColored = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={IframeColoredSvg} {...props} />
));
IframeColored.displayName = 'IframeColored';
export default IframeColored;
