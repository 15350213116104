import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const SolarColoredSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 20"
      ref={ref}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="url(#solar-a)"
        fillRule="evenodd"
        d="m12.62 3.067-.218.764h1.517l.216-.764H12.62Zm-2.087 1.337-.22.764h3.227l.216-.764h-3.223Zm-2.147 2.1.22-.763h4.77l-.215.763H8.386Zm-5.654.573-.22.764h10.27l.216-.764H2.732Zm.385 2.101.22-.764h9.281l-.216.764H3.117Zm.53 8.022.22-.764h-1.52l-.214.764h1.513Zm2.309-2.1-.22.763H2.513l.214-.764h3.229Zm1.706-.574.22-.764H3.106l-.214.764h4.77Zm6.094-2.1-.22.763H3.271l.214-.764h10.271Zm-.827-.574.22-.764H3.864l-.214.764h9.28Zm-.66-2.1-.22.763H3.344l.214-.764h8.712Z"
        clipRule="evenodd"
      />
      <defs>
        <linearGradient id="solar-a" x1={8.134} x2={8.134} y1={3.067} y2={17.2} gradientUnits="userSpaceOnUse">
          <stop stopColor="#B0BFFF" />
          <stop offset={1} stopColor="#805CFB" />
        </linearGradient>
      </defs>
    </svg>
  )),
  { width: 16, height: 20 },
);
const SolarColored = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={SolarColoredSvg} {...props} />
));
SolarColored.displayName = 'SolarColored';
export default SolarColored;
