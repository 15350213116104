import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const ArrowDownRightSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M17 15.586v-9.09h2V19H6.5v-2h9.086L4.293 5.707l1.414-1.414L17 15.586Z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const ArrowDownRight = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={ArrowDownRightSvg} {...props} />
));
ArrowDownRight.displayName = 'ArrowDownRight';
export default ArrowDownRight;
