import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const AnnouncementsSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M12 5c-.683 0-1.25.57-1.25 1.243 0 .687.567 1.257 1.25 1.257s1.25-.57 1.25-1.257A1.26 1.26 0 0 0 12 5ZM11 15V9h2v6h-2Z" />
      <path
        fillRule="evenodd"
        d="M4.5 2A2.5 2.5 0 0 0 2 4.5V22l4-4h13.5a2.5 2.5 0 0 0 2.5-2.5v-11A2.5 2.5 0 0 0 19.5 2h-15Zm15 14a.5.5 0 0 0 .5-.5v-11a.5.5 0 0 0-.5-.5h-15a.5.5 0 0 0-.5.5v12.672L5.172 16H19.5Z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Announcements = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={AnnouncementsSvg} {...props} />
));
Announcements.displayName = 'Announcements';
export default Announcements;
