import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const CallEndFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#call--a)">
        <path
          fillRule="evenodd"
          d="m8.261 9.478.363 3.998a1 1 0 0 1-.514.967l-3.522 1.934a1 1 0 0 1-1.192-.173L.29 13.07a1 1 0 0 1 .035-1.442C3.472 8.747 7.545 7 12 7c4.456 0 8.528 1.748 11.675 4.627a1 1 0 0 1 .024 1.453l-3.157 3.085a1 1 0 0 1-1.177.164l-3.49-1.9a1 1 0 0 1-.519-.968l.363-3.988A14.8 14.8 0 0 0 12 9a14.8 14.8 0 0 0-3.739.478Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="call--a">
          <path d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  )),
  { width: 24, height: 24 },
);
const CallEndFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={CallEndFilledSvg} {...props} />
));
CallEndFilled.displayName = 'CallEndFilled';
export default CallEndFilled;
