import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const BrFinanceSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#br-fi-a)">
        <path d="M20.492 5v2h1.5V5a2 2 0 0 0-2-2h-16v4h1.5V4.5h14.5a.5.5 0 0 1 .5.5Z" />
        <path
          fillRule="evenodd"
          d="M2.992 8h7.5a2.5 2.5 0 0 0 5 0h7.5v11a2 2 0 0 1-2 2h-16a2 2 0 0 1-2-2V8Zm10.17 6.851c.921.224 2.08.505 2.08 1.874 0 .911-.698 1.618-1.715 1.796v.979h-1.164v-1.013c-.966-.238-1.62-1.004-1.62-1.983H12c0 .528.422.868 1.026.868.56 0 .948-.255.948-.646 0-.433-.534-.57-1.164-.73-.905-.232-2.008-.514-2.008-1.798 0-.868.637-1.532 1.56-1.71V11.5h1.164v1.004c1.043.23 1.526 1.03 1.526 1.915h-1.32c-.008-.45-.344-.791-.896-.791-.483 0-.784.212-.784.561 0 .393.503.515 1.11.662Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="br-fi-a">
          <path d="M0 0H24V24H0z" transform="translate(.992)" />
        </clipPath>
      </defs>
    </svg>
  )),
  { width: 25, height: 24 },
);
const BrFinance = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={BrFinanceSvg} {...props} />
));
BrFinance.displayName = 'BrFinance';
export default BrFinance;
