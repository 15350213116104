import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const ImageSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M6 8.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0ZM8.5 8a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1Z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M21 3.064 3 2.996V21h15.5a2.5 2.5 0 0 0 2.5-2.5V3.064ZM5 19V5.004l14 .052v7.75l-3.476-2.022L5.639 19H5Zm3.768 0H18.5a.5.5 0 0 0 .5-.5v-3.38l-3.274-1.904L8.768 19Z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Image = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={ImageSvg} {...props} />
));
Image.displayName = 'Image';
export default Image;
