import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const IntercomSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g>
        <path d="M34.634 22.011c0 .357-.14.699-.39.95a1.324 1.324 0 0 1-1.88 0 1.351 1.351 0 0 1-.39-.95v-12.01c0-.357.14-.7.39-.952a1.323 1.323 0 0 1 1.88 0c.25.253.39.595.39.951v12.011Zm-.461 8.322c-.176.178-5.124 4.345-14.195 4.345-9.07 0-13.986-4.145-14.195-4.322a1.32 1.32 0 0 1-.46-.902 1.335 1.335 0 0 1 .306-.965 1.322 1.322 0 0 1 1.87-.144c.077.055 4.497 3.677 12.468 3.677s12.424-3.644 12.468-3.678a1.343 1.343 0 0 1 1.88.145 1.33 1.33 0 0 1-.131 1.855l-.011-.01ZM5.31 10c.02-.358.179-.693.442-.932.264-.24.61-.364.965-.346.328.02.638.16.87.396.23.236.368.55.383.882V21.99c0 .357-.14.698-.39.95a1.323 1.323 0 0 1-1.88 0 1.351 1.351 0 0 1-.39-.95V10Zm6.674-2.667c.02-.357.179-.692.442-.932.264-.24.61-.364.965-.345.328.018.638.16.87.395.23.236.368.55.383.882v17.778c0 .357-.14.699-.39.95a1.323 1.323 0 0 1-1.88 0 1.351 1.351 0 0 1-.39-.95V7.333Zm6.707-.666c0-.357.14-.699.39-.95a1.323 1.323 0 0 1 1.88 0c.25.251.39.593.39.95V26c0 .357-.14.698-.39.95a1.323 1.323 0 0 1-1.88 0 1.352 1.352 0 0 1-.39-.95V6.667Zm6.597.666c0-.356.14-.698.39-.95a1.323 1.323 0 0 1 1.88 0c.25.252.39.594.39.95v17.778c0 .357-.14.699-.39.95a1.324 1.324 0 0 1-1.88 0 1.351 1.351 0 0 1-.39-.95V7.333ZM34.997 0H5.003a4.924 4.924 0 0 0-1.907.37 4.966 4.966 0 0 0-1.621 1.081A5.025 5.025 0 0 0 .388 3.077 5.068 5.068 0 0 0 0 5v30c.003.66.135 1.314.388 1.923a5.027 5.027 0 0 0 1.087 1.626 4.967 4.967 0 0 0 1.62 1.08c.606.25 1.254.375 1.908.37h29.994c.653.005 1.3-.12 1.904-.369a4.961 4.961 0 0 0 1.62-1.078 5.023 5.023 0 0 0 1.088-1.622A5.07 5.07 0 0 0 40 35.011V5.001a5.073 5.073 0 0 0-.387-1.921 5.028 5.028 0 0 0-1.084-1.625A4.964 4.964 0 0 0 36.912.374 4.924 4.924 0 0 0 35.008 0" />
      </g>
    </svg>
  )),
  { width: 40, height: 40 },
);
const Intercom = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={IntercomSvg} {...props} />
));
Intercom.displayName = 'Intercom';
export default Intercom;
