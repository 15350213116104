import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const ConvertSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M15.707 12.707 21.414 7l-5.707-5.707-1.414 1.414L17.586 6H3v2h14.586l-3.293 3.293 1.414 1.414ZM6.414 16H21v2H6.414l3.293 3.293-1.414 1.414L2.586 17l5.707-5.707 1.414 1.414L6.414 16Z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Convert = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={ConvertSvg} {...props} />
));
Convert.displayName = 'Convert';
export default Convert;
