import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const UikitSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M6.4 2c2.43 0 4.4 2.052 4.4 4.583s-1.97 4.584-4.4 4.584c-2.43 0-4.4-2.053-4.4-4.584V2h4.4ZM2 12.833C2 17.896 5.94 22 10.8 22v-9.167H2ZM22 12c0 5.523-4.299 10-9.6 10V2C17.7 2 22 6.477 22 12Z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Uikit = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={UikitSvg} {...props} />
));
Uikit.displayName = 'Uikit';
export default Uikit;
