import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const ModerateSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="m18.263 8.778 1.415-1.414L13.314 1l-1.415 1.414 6.364 6.364ZM9.778 17.264l-1.414 1.414L2 12.314 3.414 10.9l6.364 6.364Z" />
      <path
        fillRule="evenodd"
        d="m13.314 13.728 7.276 7.276 1.414-1.414-7.276-7.276 2.475-2.475-6.364-6.364-6.364 6.364 6.364 6.364 2.475-2.475Zm-2.475-.354 3.536-3.535-3.536-3.536L7.303 9.84l3.536 3.535Z"
        clipRule="evenodd"
      />
      <path d="M17 20.004H3v2h14v-2Z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Moderate = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={ModerateSvg} {...props} />
));
Moderate.displayName = 'Moderate';
export default Moderate;
