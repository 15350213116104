import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const TextAlignMiddleSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="m7.293 5.207 1.414-1.414L11 6.086V1h2v5.086l2.293-2.293 1.414 1.414L12 9.914 7.293 5.207ZM7.293 18.793l1.414 1.414L11 17.914V23h2v-5.086l2.293 2.293 1.414-1.414L12 14.086l-4.707 4.707ZM3 13h18v-2H3v2Z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const TextAlignMiddle = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={TextAlignMiddleSvg} {...props} />
));
TextAlignMiddle.displayName = 'TextAlignMiddle';
export default TextAlignMiddle;
