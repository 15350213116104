import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const UserSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M12 9a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm4-2a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z"
        clipRule="evenodd"
      />
      <path d="M12 14.5a6 6 0 0 0-6 6h-.004v.5h-2v-.5H4a8 8 0 1 1 16 0v.5h-2v-.5a6 6 0 0 0-6-6Z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const User = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={UserSvg} {...props} />
));
User.displayName = 'User';
export default User;
