import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const UserChatHistoryFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M3 6h18V4H3v2ZM5.906 15.347H3v-2h2.906v2ZM7.339 20H3v-2h4.339v2ZM7.79 10.663H3v-2h4.79v2Z" />
      <path
        fillRule="evenodd"
        d="M14.75 7.5a6.25 6.25 0 1 0 0 12.5 6.25 6.25 0 0 0 0-12.5ZM14 11h2v2.098l1.49 1.49-1.414 1.414L14 13.926V11Z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const UserChatHistoryFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={UserChatHistoryFilledSvg} {...props} />
));
UserChatHistoryFilled.displayName = 'UserChatHistoryFilled';
export default UserChatHistoryFilled;
