import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const ThumbsDownSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M5.965 13.697 6.42 14l.386-.386 4.138-4.139h3.724v-7c0-.92-.746-1.666-1.667-1.666H4.824c-.664 0-1.265.394-1.529 1.004L1.472 6.02c-.09.21-.137.435-.137.663v1.126c0 .92.746 1.666 1.666 1.666h2.46l-.58 2.13a1.667 1.667 0 0 0 .684 1.825l.4.267ZM10 2.142H4.824a.333.333 0 0 0-.305.2L2.695 6.55a.333.333 0 0 0-.027.133v1.126c0 .184.15.333.333.333h4.206l-.23.842-.81 2.972a.333.333 0 0 0 .087.324l3.747-3.747v-6.39Zm1.334 0v6h2V2.475A.333.333 0 0 0 13 2.142h-1.666Z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 16, height: 16 },
);
const ThumbsDown = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={ThumbsDownSvg} {...props} />
));
ThumbsDown.displayName = 'ThumbsDown';
export default ThumbsDown;
