import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const DetectSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 81" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g fillRule="evenodd" clipPath="url(#detec-a)" clipRule="evenodd">
        <path d="M39.965 26.7a4.233 4.233 0 1 0 0 8.467 4.233 4.233 0 0 0 0-8.467ZM29.73 30.933c0-5.651 4.582-10.233 10.234-10.233 5.651 0 10.233 4.582 10.233 10.233 0 5.652-4.581 10.234-10.233 10.234S29.73 36.585 29.73 30.933Z" />
        <path d="m31.542 18.434-2.088 2.154c-2.62 2.705-4.22 6.334-4.22 10.346 0 4.02 1.605 7.674 4.21 10.334l2.099 2.144-4.288 4.197-2.098-2.143a20.734 20.734 0 0 1-5.924-14.532c0-5.655 2.267-10.76 5.913-14.521l2.087-2.155 4.31 4.176Z" />
        <path d="m23.541 10.473-2.108 2.135c-4.67 4.73-7.535 11.182-7.535 18.326 0 7.143 2.865 13.595 7.535 18.325l2.108 2.135-4.27 4.216-2.107-2.135c-5.73-5.804-9.266-13.752-9.266-22.541 0-8.79 3.536-16.738 9.266-22.541l2.107-2.135 4.27 4.215Z" />
        <path d="M42.965 35.166V73.5h-6V35.166h6Z" />
        <path d="m52.742 14.257 2.099 2.144a20.733 20.733 0 0 1 5.923 14.532c0 5.655-2.267 10.76-5.912 14.521l-2.088 2.154-4.309-4.175 2.088-2.154c2.621-2.705 4.221-6.334 4.221-10.346 0-4.02-1.606-7.674-4.21-10.334l-2.099-2.144 4.287-4.198Z" />
        <path d="m60.727 6.258 2.107 2.135c5.73 5.803 9.266 13.751 9.266 22.54 0 8.79-3.536 16.738-9.266 22.542l-2.107 2.135-4.27-4.216 2.108-2.135c4.67-4.73 7.535-11.182 7.535-18.325 0-7.144-2.865-13.596-7.535-18.326l-2.108-2.135 4.27-4.215Z" />
      </g>
      <defs>
        <clipPath id="detec-a">
          <path d="M0 0H80V80H0z" transform="translate(0 .5)" />
        </clipPath>
      </defs>
    </svg>
  )),
  { width: 80, height: 81 },
);
const Detect = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={DetectSvg} {...props} />
));
Detect.displayName = 'Detect';
export default Detect;
