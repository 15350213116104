import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const GithubColoredSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      ref={ref}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="#0D0D0D"
        d="M12 0C5.372 0 0 5.279 0 11.792c0 5.209 3.438 9.628 8.207 11.188.6.108.82-.256.82-.57 0-.279-.011-1.02-.017-2.004-3.338.712-4.042-1.581-4.042-1.581-.546-1.362-1.333-1.724-1.333-1.724-1.09-.732.083-.717.083-.717 1.204.083 1.838 1.215 1.838 1.215 1.07 1.801 2.808 1.281 3.492.98.11-.762.419-1.282.762-1.577-2.665-.297-5.466-1.31-5.466-5.827 0-1.287.468-2.34 1.235-3.164-.123-.298-.535-1.497.118-3.12 0 0 1.007-.318 3.3 1.208A11.684 11.684 0 0 1 12 5.702c1.019.005 2.045.135 3.004.397C17.295 4.573 18.3 4.89 18.3 4.89c.655 1.624.243 2.823.12 3.12.769.825 1.233 1.878 1.233 3.165 0 4.53-2.805 5.526-5.479 5.818.431.364.815 1.084.815 2.184 0 1.576-.015 2.847-.015 3.234 0 .316.216.682.825.567C20.564 21.416 24 17 24 11.792 24 5.28 18.627 0 12 0Z"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const GithubColored = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={GithubColoredSvg} {...props} />
));
GithubColored.displayName = 'GithubColored';
export default GithubColored;
