import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const LiveFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M4 12a8 8 0 1 1 11 7.419v2.123c4.057-1.274 7-5.064 7-9.542 0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.478 2.943 8.268 7 9.542V19.42A8.003 8.003 0 0 1 4 12Z" />
      <path d="M15 17.197a6 6 0 1 0-6 0v-2.551a4 4 0 1 1 6 0v2.551Z" />
      <path d="M13 13.732A2 2 0 0 0 12 10a2 2 0 0 0-1 3.732V22h2v-8.268Z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const LiveFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={LiveFilledSvg} {...props} />
));
LiveFilled.displayName = 'LiveFilled';
export default LiveFilled;
