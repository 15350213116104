import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const DotnetSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Zm-1.041-8.46H9.941L6.3 10.015a2.281 2.281 0 0 1-.226-.433h-.03c.034.315.047.633.038.95v5.008h-.828V8.613h1.077l3.544 5.426c.148.226.243.38.287.464h.02a7.199 7.199 0 0 1-.05-1.019V8.613h.827v6.927Zm-8.15 0a.39.39 0 0 1-.289-.12.393.393 0 0 1-.118-.286c0-.107.042-.21.118-.286a.397.397 0 0 1 .576 0 .396.396 0 0 1 0 .572.397.397 0 0 1-.288.12Zm9.372 0h3.664l.004-.76h-2.844v-2.401h2.488v-.73h-2.488V9.346h2.67v-.733H12.18v6.927Zm8.965-6.194H19.1v6.194h-.822V9.346h-2.022v-.733h4.89v.733Z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Dotnet = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={DotnetSvg} {...props} />
));
Dotnet.displayName = 'Dotnet';
export default Dotnet;
