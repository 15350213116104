import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const HideVariableSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M17.505 14.656v-2.992c0-.376.249-.694.591-.797.08.007.16.011.242.011h.833V9.164h-.833a2.53 2.53 0 0 0-.242.012.834.834 0 0 1-.591-.798V4.164c0-1.15-.933-2.083-2.084-2.083h-2.083v1.667h2.083c.23 0 .417.186.417.416v4.214c0 .63.232 1.204.616 1.643a2.49 2.49 0 0 0-.616 1.643v1.325l1.667 1.667Zm-.214 2.143-1.453-1.453v.532c0 .23-.187.417-.417.417h-1.666v1.666h1.666c.82 0 1.53-.473 1.87-1.162ZM9.71 9.219l1.074 1.074A.834.834 0 1 1 9.71 9.218Zm3.918 1.56-1.072-1.071a.834.834 0 1 1 1.072 1.071ZM6.596 3.748 4.93 2.08H6.67v1.667h-.075ZM3.15 2.658l1.18 1.18a.416.416 0 0 0-.158.326v4.214a2.49 2.49 0 0 1-.616 1.643 2.49 2.49 0 0 1 .616 1.643v4.214c0 .23.187.417.417.417H6.67v1.666H4.588a2.083 2.083 0 0 1-2.083-2.083v-4.214a.834.834 0 0 0-.592-.797c-.08.007-.16.011-.242.011H.838V9.164h.833c.082 0 .162.004.242.012a.834.834 0 0 0 .592-.798V4.164c0-.592.247-1.127.644-1.506Zm4.356 7.34a.833.833 0 1 1-1.667 0 .833.833 0 0 1 1.667 0Z"
        clipRule="evenodd"
      />
      <path fillRule="evenodd" d="M17.951 19.805.441 2.295 1.62 1.118l17.51 17.51-1.179 1.178Z" clipRule="evenodd" />
    </svg>
  )),
  { width: 20, height: 20 },
);
const HideVariable = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={HideVariableSvg} {...props} />
));
HideVariable.displayName = 'HideVariable';
export default HideVariable;
