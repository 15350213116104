import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const BrBeYourBestSelfSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g fillRule="evenodd" clipPath="url(#br-be-a)" clipRule="evenodd">
        <path d="M17.003 23v-3.5h-1.5v1.513l-3.5-.991-3.5.99V19.5h-1.5V23l5-1.415 5 1.415Z" />
        <path d="M18.435 16.295A9 9 0 1 0 5.571 3.705a9 9 0 0 0 12.864 12.59Zm-5.31-7.84L12.004 5 10.88 8.455H7.248l2.939 2.135-1.123 3.455 2.939-2.135 2.939 2.135-1.123-3.455 2.94-2.135h-3.633Z" />
      </g>
      <defs>
        <clipPath id="br-be-a">
          <path d="M0 0H24V24H0z" transform="translate(.003)" />
        </clipPath>
      </defs>
    </svg>
  )),
  { width: 25, height: 24 },
);
const BrBeYourBestSelf = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={BrBeYourBestSelfSvg} {...props} />
));
BrBeYourBestSelf.displayName = 'BrBeYourBestSelf';
export default BrBeYourBestSelf;
