import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const GenerateTextSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M2 7h20V5H2v2ZM2 13h10v-2H2v2ZM10.5 19H2v-2h8.5v2Z" />
      <path
        fillRule="evenodd"
        d="M19.745 13.255 17.5 8.764l-2.246 4.49-4.49 2.246 4.49 2.245 2.246 4.491 2.245-4.49 4.49-2.246-4.49-2.245Zm-3 1.49.755-1.509.754 1.51 1.51.754-1.51.755-.754 1.509-.755-1.51-1.51-.754 1.51-.755Z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 25, height: 24 },
);
const GenerateText = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={GenerateTextSvg} {...props} />
));
GenerateText.displayName = 'GenerateText';
export default GenerateText;
