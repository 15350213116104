import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const ChangeSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M18.586 7H5v4H3V5h15.586l-2.293-2.293 1.414-1.414L22.414 6l-4.707 4.707-1.414-1.414L18.586 7ZM7.707 14.707 5.414 17H19v-4h2v6H5.414l2.293 2.293-1.414 1.414L1.586 18l4.707-4.707 1.414 1.414Z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Change = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={ChangeSvg} {...props} />
));
Change.displayName = 'Change';
export default Change;
