import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const TextMonospaceSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M10.334 2.333H9.001L5.667 13.668h1.334l3.333-11.333ZM4.472 4.667l.943.943L3.22 7.805 5.415 10l-.943.943-3.138-3.138 3.138-3.138ZM11.53 4.667l-.944.943 2.196 2.195L10.586 10l.943.943 3.138-3.138-3.138-3.138Z" />
    </svg>
  )),
  { width: 16, height: 16 },
);
const TextMonospace = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={TextMonospaceSvg} {...props} />
));
TextMonospace.displayName = 'TextMonospace';
export default TextMonospace;
