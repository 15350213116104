import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const BotColoredSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      ref={ref}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="#6210CC"
        fillRule="evenodd"
        d="M12 .974a2.505 2.505 0 0 0-1 4.803v1.702h2V5.777a2.506 2.506 0 0 0-1-4.803ZM.252 16.579v-6h2v6h-2Zm21.5-6v6h2v-6h-2Zm-3.5-3.1h-15v11.5a2.5 2.5 0 0 0 2.5 2.5h12.5a2.5 2.5 0 0 0 2.5-2.5v-9a2.5 2.5 0 0 0-2.5-2.5Zm-8.467 4.475v3.25a1 1 0 1 1-2 0v-3.25a1 1 0 1 1 2 0Zm6.484 0v3.25a1 1 0 1 1-2 0v-3.25a1 1 0 1 1 2 0Z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const BotColored = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={BotColoredSvg} {...props} />
));
BotColored.displayName = 'BotColored';
export default BotColored;
