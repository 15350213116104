import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const TextViewSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g>
        <g>
          <path d="M18.333 5.834H1.667V4.167h16.666v1.667ZM1.667 10.834h16.666V9.167H1.667v1.667ZM12.5 14.167H1.667v1.667H12.5v-1.667Z" />
        </g>
      </g>
    </svg>
  )),
  { width: 20, height: 20 },
);
const TextView = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={TextViewSvg} {...props} />
));
TextView.displayName = 'TextView';
export default TextView;
