import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const RetweetSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M18 5H4V3h16v9.586l2.293-2.293 1.414 1.414L19 16.414l-4.707-4.707 1.414-1.414L18 12.586V5ZM4 11.414l-2.293 2.293-1.414-1.414L5 7.586l4.707 4.707-1.414 1.414L6 11.414V19h14v2H4v-9.586Z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Retweet = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={RetweetSvg} {...props} />
));
Retweet.displayName = 'Retweet';
export default Retweet;
