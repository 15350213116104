import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const SnippetSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M15.833 4.167v11.666H4.167V4.167h11.666Zm0-1.667H4.167C3.25 2.5 2.5 3.25 2.5 4.167v11.666c0 .917.75 1.667 1.667 1.667h11.666c.917 0 1.667-.75 1.667-1.667V4.167c0-.917-.75-1.667-1.667-1.667Z" />
      <path d="M11.667 14.167H5.833V12.5h5.834v1.667Zm2.5-3.334H5.833V9.167h8.334v1.666Zm0-3.333H5.833V5.833h8.334V7.5Z" />
    </svg>
  )),
  { width: 20, height: 20 },
);
const Snippet = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={SnippetSvg} {...props} />
));
Snippet.displayName = 'Snippet';
export default Snippet;
