import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const CallFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M19.55 14.25A2.012 2.012 0 0 1 21 16.193L20.978 21l-1.046-.046c-4.358-.194-8.566-1.876-11.788-5.099C4.922 12.633 3.24 8.423 3.047 4.063L3 3l4.84.056c.882.01 1.655.595 1.905 1.442l1.024 3.469a2.012 2.012 0 0 1-.641 2.115l-2.454 2.046a15.189 15.189 0 0 0 4.196 4.198l2.047-2.458a2.01 2.01 0 0 1 2.107-.644l3.527 1.027Z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const CallFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={CallFilledSvg} {...props} />
));
CallFilled.displayName = 'CallFilled';
export default CallFilled;
