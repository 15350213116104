import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const ShortcutSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="m17.586 9-3.293-3.293 1.414-1.414L21.414 10l-5.707 5.707-1.414-1.414L17.586 11H10a5 5 0 0 0-5 5.001V20H3v-3.999A7 7 0 0 1 10 9h7.586Z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Shortcut = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={ShortcutSvg} {...props} />
));
Shortcut.displayName = 'Shortcut';
export default Shortcut;
