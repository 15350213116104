import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const AlignRightSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 20" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M1.998 2.5h16.667v1.667H1.998V2.5ZM7.831 5.833h10.834V7.5H7.83V5.833ZM18.665 9.167H1.998v1.666h16.667V9.167ZM7.831 12.5h10.834v1.667H7.83V12.5ZM18.665 15.833H1.998V17.5h16.667v-1.667Z" />
    </svg>
  )),
  { width: 21, height: 20 },
);
const AlignRight = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={AlignRightSvg} {...props} />
));
AlignRight.displayName = 'AlignRight';
export default AlignRight;
