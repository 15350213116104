import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const FacebookColoredSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      ref={ref}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="#1877F2"
        d="M23 12c0-6.072-4.928-11-11-11C5.926 1 .998 5.928.998 12a10.99 10.99 0 0 0 9.29 10.865v-7.692h-2.81V12h2.81V9.576c0-2.753 1.631-4.282 4.146-4.282 1.201 0 2.447.226 2.447.226v2.685H15.5c-1.37 0-1.801.861-1.801 1.722v2.062h3.059l-.487 3.172h-2.572v7.693A10.969 10.969 0 0 0 23 12Z"
      />
      <path
        fill="#fff"
        d="m16.282 15.184.487-3.172h-3.06V9.95c0-.872.443-1.722 1.802-1.722h1.382V5.532s-1.246-.227-2.447-.227c-2.515 0-4.146 1.53-4.146 4.283v2.424H7.49v3.172h2.81v7.693c.555.09 1.133.136 1.71.136.578 0 1.156-.046 1.711-.137v-7.692h2.56Z"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const FacebookColored = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={FacebookColoredSvg} {...props} />
));
FacebookColored.displayName = 'FacebookColored';
export default FacebookColored;
