import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const LiveHorizontalSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M19.418 11.534c0 2.867-1.787 5.318-4.311 6.31v2.384c3.799-1.08 6.58-4.563 6.58-8.693 0-4.993-4.064-9.04-9.076-9.04-5.013 0-9.077 4.047-9.077 9.04 0 4.13 2.782 7.613 6.58 8.693v-2.384c-2.524-.992-4.31-3.443-4.31-6.31 0-3.744 3.047-6.78 6.807-6.78 3.76 0 6.807 3.036 6.807 6.78Zm12.646-7.251c.94 0 1.719.76 1.719 1.695 0 .934-.749 1.711-1.702 1.711-.954 0-1.719-.745-1.719-1.694 0-.95.749-1.712 1.702-1.712Zm-5.839.492h2.829v13.54h-2.829V4.775Zm4.433 3.73h2.829v9.81h-2.829v-9.81Zm11.072 0-2.186 5.974-2.227-5.974h-3.069l4.093 9.81h2.407l4.071-9.81H41.73Zm7.964-.199c2.669 0 4.855 2.078 4.855 4.775h-.002c0 .22-.02.678-.06.998h-7.322c.2 1.279 1.184 2.098 2.508 2.098 1.104 0 1.805-.48 1.966-1.16h2.769c-.322 2.12-2.188 3.497-4.654 3.497-2.91 0-5.176-2.297-5.176-5.154 0-2.856 2.247-5.054 5.116-5.054Zm-.12 2.278c-1.084 0-1.926.539-2.267 1.698H51.6c-.081-.999-.943-1.698-2.027-1.698Zm-34.467 2.908c.426-.539.68-1.219.68-1.958a3.17 3.17 0 0 0-3.176-3.163 3.17 3.17 0 0 0-3.177 3.163c0 .74.254 1.42.68 1.958v2.865a5.42 5.42 0 0 1-2.95-4.822c0-2.995 2.44-5.424 5.447-5.424s5.445 2.428 5.445 5.424a5.42 5.42 0 0 1-2.95 4.822v-2.865Zm-3.63 7.012v-7.558a1.803 1.803 0 0 1-.682-1.412c0-.998.813-1.807 1.816-1.807 1.002 0 1.815.809 1.815 1.807 0 .571-.266 1.08-.68 1.412v7.558c-.371.046-.749.07-1.132.07h-.007c-.383 0-.76-.024-1.13-.07Z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 58, height: 24 },
);
const LiveHorizontal = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={LiveHorizontalSvg} {...props} />
));
LiveHorizontal.displayName = 'LiveHorizontal';
export default LiveHorizontal;
