import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const SlackColoredSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 40 40"
      ref={ref}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g>
        <g>
          <path
            fill="#E01E5A"
            d="M9.37 24.836a3.861 3.861 0 0 1-3.85 3.852 3.861 3.861 0 0 1-3.853-3.852 3.861 3.861 0 0 1 3.852-3.851h3.852v3.851ZM11.31 24.837a3.861 3.861 0 0 1 3.853-3.852 3.861 3.861 0 0 1 3.851 3.852v9.644a3.861 3.861 0 0 1-3.851 3.852 3.861 3.861 0 0 1-3.852-3.852v-9.644Z"
          />
          <path
            fill="#36C5F0"
            d="M15.163 9.37A3.861 3.861 0 0 1 11.31 5.52a3.861 3.861 0 0 1 3.852-3.852 3.861 3.861 0 0 1 3.852 3.852V9.37h-3.852ZM15.163 11.311a3.861 3.861 0 0 1 3.852 3.852 3.861 3.861 0 0 1-3.852 3.852H5.519a3.861 3.861 0 0 1-3.852-3.852 3.861 3.861 0 0 1 3.852-3.852h9.644Z"
          />
          <path
            fill="#2EB67D"
            d="M30.63 15.163a3.861 3.861 0 0 1 3.851-3.852 3.861 3.861 0 0 1 3.852 3.852 3.861 3.861 0 0 1-3.852 3.851H30.63v-3.851ZM28.69 15.163a3.861 3.861 0 0 1-3.852 3.852 3.861 3.861 0 0 1-3.852-3.852V5.519a3.861 3.861 0 0 1 3.852-3.852 3.861 3.861 0 0 1 3.851 3.852v9.644Z"
          />
          <path
            fill="#ECB22E"
            d="M24.838 30.629a3.861 3.861 0 0 1 3.852 3.852 3.861 3.861 0 0 1-3.852 3.851 3.861 3.861 0 0 1-3.852-3.851v-3.852h3.852ZM24.838 28.688a3.861 3.861 0 0 1-3.852-3.852 3.861 3.861 0 0 1 3.852-3.851h9.644a3.861 3.861 0 0 1 3.852 3.851 3.861 3.861 0 0 1-3.852 3.852h-9.644Z"
          />
        </g>
      </g>
    </svg>
  )),
  { width: 40, height: 40 },
);
const SlackColored = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={SlackColoredSvg} {...props} />
));
SlackColored.displayName = 'SlackColored';
export default SlackColored;
