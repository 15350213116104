import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const VendorWixSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 20" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M16 7.333h-.884a.74.74 0 0 0-.612.325L13.322 9.4a.08.08 0 0 1-.131 0l-1.182-1.743a.739.739 0 0 0-.612-.325h-.884l2.106 3.106-2.094 3.089h.884a.74.74 0 0 0 .612-.325l1.17-1.726a.08.08 0 0 1 .131 0l1.17 1.726a.739.739 0 0 0 .613.325h.883l-2.093-3.089L16 7.333ZM8.695 7.967v5.561h.422c.35 0 .634-.284.634-.634v-5.56h-.423a.634.634 0 0 0-.633.633ZM7.92 7.333h-.376a.886.886 0 0 0-.864.69l-.84 3.697-.737-3.451a1.174 1.174 0 0 0-1.46-.883 1.194 1.194 0 0 0-.835.91l-.729 3.42-.839-3.693a.887.887 0 0 0-.864-.69H0l1.409 6.195h.534c.484 0 .902-.339 1.003-.813l.901-4.226a.115.115 0 0 1 .112-.09c.053 0 .1.038.111.09l.902 4.227c.1.473.52.812 1.004.812h.535l1.41-6.195Z" />
    </svg>
  )),
  { width: 16, height: 20 },
);
const VendorWix = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={VendorWixSvg} {...props} />
));
VendorWix.displayName = 'VendorWix';
export default VendorWix;
