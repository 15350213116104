import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const InReviewFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M3 4h2v3.264a3 3 0 0 0 1.658 2.683L10.764 12l-4.106 2.053A3 3 0 0 0 5 16.736V20H3v2h18v-2h-2v-3.264a3 3 0 0 0-1.658-2.683L13.236 12l4.106-2.053A3 3 0 0 0 19 7.264V4h2V2H3v2Zm4 3V4h10v3H7Z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const InReviewFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={InReviewFilledSvg} {...props} />
));
InReviewFilled.displayName = 'InReviewFilled';
export default InReviewFilled;
