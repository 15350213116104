import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const FileTwotoneSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path id="Color_02" fillRule="evenodd" d="M4 2h10.414L20 7.586V22H4V2Z" clipRule="evenodd" />
      <path id="Color_01" d="M14 4v4h4l-4-4Z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const FileTwotone = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={FileTwotoneSvg} {...props} />
));
FileTwotone.displayName = 'FileTwotone';
export default FileTwotone;
