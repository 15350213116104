import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const MetaColoredSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 20"
      ref={ref}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="#0081FB"
        d="M1.728 11.675c0 .611.134 1.08.31 1.364.23.372.572.53.922.53.45 0 .863-.113 1.657-1.212.637-.88 1.387-2.117 1.891-2.892l.855-1.313c.594-.912 1.28-1.926 2.069-2.613.643-.56 1.337-.872 2.035-.872 1.172 0 2.289.68 3.144 1.953C15.546 8.016 16 9.773 16 11.587c0 1.079-.213 1.871-.574 2.497-.35.605-1.03 1.21-2.176 1.21v-1.726c.98 0 1.225-.901 1.225-1.933 0-1.47-.342-3.101-1.097-4.267-.536-.827-1.23-1.332-1.995-1.332-.826 0-1.49.623-2.238 1.734-.397.59-.805 1.31-1.263 2.122l-.504.893c-1.013 1.796-1.27 2.204-1.776 2.88-.887 1.181-1.645 1.63-2.642 1.63-1.184 0-1.932-.513-2.396-1.286C.186 13.38 0 12.554 0 11.613l1.728.062Z"
      />
      <path
        fill="url(#meta--a)"
        d="M1.363 6.742c.792-1.221 1.935-2.075 3.247-2.075.76 0 1.514.225 2.303.868.862.704 1.781 1.863 2.928 3.773l.411.686c.993 1.653 1.558 2.504 1.888 2.905.425.516.723.67 1.11.67.98 0 1.225-.902 1.225-1.934L16 11.587c0 1.079-.213 1.871-.574 2.497-.35.605-1.03 1.21-2.176 1.21-.713 0-1.344-.155-2.041-.813-.537-.505-1.164-1.402-1.646-2.21L8.128 9.876c-.72-1.203-1.38-2.1-1.763-2.506-.41-.437-.94-.965-1.783-.965-.683 0-1.262.48-1.748 1.212l-1.471-.874Z"
      />
      <path
        fill="url(#meta--b)"
        d="M4.582 6.404c-.683 0-1.262.48-1.748 1.212-.686 1.036-1.106 2.578-1.106 4.06 0 .61.134 1.08.31 1.363l-1.474.97C.186 13.38 0 12.554 0 11.613c0-1.71.47-3.494 1.363-4.87.792-1.222 1.935-2.076 3.247-2.076l-.028 1.737Z"
      />
      <defs>
        <linearGradient id="meta--a" x1={2.221} x2={14.257} y1={11.177} y2={11.841} gradientUnits="userSpaceOnUse">
          <stop stopColor="#0064E1" />
          <stop offset={0.4} stopColor="#0064E1" />
          <stop offset={0.83} stopColor="#0073EE" />
          <stop offset={1} stopColor="#0082FB" />
        </linearGradient>
        <linearGradient id="meta--b" x1={2.504} x2={2.504} y1={12.401} y2={8.339} gradientUnits="userSpaceOnUse">
          <stop stopColor="#0082FB" />
          <stop offset={1} stopColor="#0064E0" />
        </linearGradient>
      </defs>
    </svg>
  )),
  { width: 16, height: 20 },
);
const MetaColored = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={MetaColoredSvg} {...props} />
));
MetaColored.displayName = 'MetaColored';
export default MetaColored;
