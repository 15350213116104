import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const BrFoodDeliverySvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#br-fo-a)">
        <path d="M15.992 7v9h7v-4.588a2 2 0 0 0-.446-1.259l-1.953-2.412A2 2 0 0 0 19.038 7h-3.046ZM12.492 3h-7.5a2 2 0 0 0-2 2v11h11.5V5a2 2 0 0 0-2-2Z" />
        <path d="M16.242 17.5h1.5v.25a1.75 1.75 0 1 0 3.5 0v-.25h1.5v.25a3.25 3.25 0 0 1-6.5 0v-.25ZM5.492 17.5h1.5v.25a1.75 1.75 0 1 0 3.5 0v-.25h1.5v.25a3.25 3.25 0 0 1-6.5 0v-.25Z" />
      </g>
      <defs>
        <clipPath id="br-fo-a">
          <path d="M0 0H24V24H0z" transform="translate(.992)" />
        </clipPath>
      </defs>
    </svg>
  )),
  { width: 25, height: 24 },
);
const BrFoodDelivery = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={BrFoodDeliverySvg} {...props} />
));
BrFoodDelivery.displayName = 'BrFoodDelivery';
export default BrFoodDelivery;
