import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const CommonSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M4.765 11.295c-.324.306-.401.545-.401.705 0 .16.077.398.401.704.325.308.842.624 1.55.908 1.413.565 3.422.933 5.685.933 2.263 0 4.272-.368 5.685-.933.708-.284 1.225-.6 1.55-.908.324-.306.401-.544.401-.704 0-.16-.077-.399-.4-.705-.326-.308-.843-.624-1.551-.907-1.413-.565-3.422-.934-5.685-.934-2.263 0-4.272.369-5.684.934-.71.283-1.226.6-1.551.907Zm.92-2.483C7.344 8.15 9.577 7.757 12 7.757c2.423 0 4.656.392 6.315 1.055.827.33 1.553.746 2.086 1.25.535.506.932 1.16.932 1.938 0 .777-.397 1.431-.932 1.937-.533.505-1.26.92-2.086 1.25-1.659.664-3.892 1.055-6.315 1.055-2.423 0-4.656-.391-6.315-1.055-.827-.33-1.553-.745-2.086-1.25-.535-.506-.932-1.16-.932-1.937 0-.777.397-1.432.932-1.938.533-.504 1.26-.92 2.086-1.25Z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M10.388 6.315C9.823 7.728 9.455 9.737 9.455 12c0 2.263.368 4.272.933 5.684.283.709.6 1.225.907 1.55.306.325.545.402.705.402.16 0 .399-.077.705-.401.307-.326.624-.842.907-1.55.565-1.413.933-3.422.933-5.685 0-2.263-.368-4.272-.933-5.685-.283-.708-.6-1.225-.907-1.55-.306-.324-.545-.402-.705-.402-.16 0-.399.078-.705.402-.307.325-.624.842-.907 1.55ZM10.062 3.6c.506-.535 1.16-.933 1.938-.933.777 0 1.431.398 1.938.933.504.533.919 1.26 1.25 2.086.663 1.659 1.054 3.891 1.054 6.315 0 2.423-.39 4.656-1.054 6.315-.331.826-.746 1.553-1.25 2.086-.507.535-1.16.932-1.938.932-.777 0-1.432-.397-1.938-.932-.504-.533-.919-1.26-1.25-2.086C8.15 16.656 7.758 14.423 7.758 12c0-2.424.39-4.656 1.054-6.315.331-.827.746-1.553 1.25-2.086Z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M12 4.363a7.636 7.636 0 1 0 0 15.273 7.636 7.636 0 0 0 0-15.273ZM2.667 12a9.333 9.333 0 1 1 18.666 0 9.333 9.333 0 0 1-18.666 0Z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Common = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={CommonSvg} {...props} />
));
Common.displayName = 'Common';
export default Common;
