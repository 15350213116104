import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const DeletedMessageFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M4.5 2H14v8.5h8v4.995a2.5 2.5 0 0 1-2.5 2.5H6.005L2 22V4.5A2.5 2.5 0 0 1 4.5 2Z" />
      <path d="m15.5 7.6 2.1-2.1-2.1-2.1L16.9 2 19 4.1 21.1 2l1.4 1.4-2.1 2.1 2.1 2.1L21.1 9 19 6.9 16.9 9l-1.4-1.4Z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const DeletedMessageFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={DeletedMessageFilledSvg} {...props} />
));
DeletedMessageFilled.displayName = 'DeletedMessageFilled';
export default DeletedMessageFilled;
