import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const LiveStudioSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M12 4.5a7.5 7.5 0 0 1 7.494 7.206l2.255 2.53C21.913 13.519 22 12.77 22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.57 3.064 8.422 7.25 9.617V18.98A7.503 7.503 0 0 1 12 4.5Z" />
      <path d="M12 6a6 6 0 0 1 6 5.958l-3.25 3.65v-1.443a3.5 3.5 0 1 0-5.5 0v3.17A6 6 0 0 1 12 6Z" />
      <path d="M13.25 13.561v3.73l-2.5 2.808V13.56a2 2 0 1 1 2.5 0ZM17.125 20.017l-2.266 2.544L16.141 24l5.359-6.017h-3.624l2.265-2.544-1.004-1.127L18.86 14 13.5 20.017h3.625Z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const LiveStudio = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={LiveStudioSvg} {...props} />
));
LiveStudio.displayName = 'LiveStudio';
export default LiveStudio;
