import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const CopyFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M4.5 2H16v3.499h-2V4H4.5a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h1.994v2H4.5A2.5 2.5 0 0 1 2 14.5v-10A2.5 2.5 0 0 1 4.5 2Z" />
      <path d="M22 7H10.5A2.5 2.5 0 0 0 8 9.5v10a2.5 2.5 0 0 0 2.5 2.5h9a2.5 2.5 0 0 0 2.5-2.5V7Z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const CopyFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={CopyFilledSvg} {...props} />
));
CopyFilled.displayName = 'CopyFilled';
export default CopyFilled;
