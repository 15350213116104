import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const AdvancedModerationHorizontalSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 220 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M2.712 3.584v10.907c0 .968.516 1.863 1.354 2.348l6.782 3.92 6.78-3.92a2.712 2.712 0 0 0 1.355-2.348V3.584H2.712Zm7.062 8.815 4.987-4.513 1.213 1.34-6.2 5.611-3.149-2.85 1.213-1.34 1.936 1.752Z"
        clipRule="evenodd"
      />
      <path d="M196.487 5.67c0 .94-.779 1.72-1.719 1.72-.94 0-1.719-.78-1.719-1.72s.779-1.7 1.719-1.7c.94 0 1.719.78 1.719 1.7ZM196.187 8.198v9.817h-2.819V8.198h2.819Z" />
      <path
        fillRule="evenodd"
        d="M47.124 4.463v13.551h-2.82v-1.399c-.679 1.02-1.739 1.6-3.118 1.6-2.54 0-4.559-2.3-4.559-5.119s2.02-5.098 4.559-5.098c1.379 0 2.439.58 3.119 1.6V4.463h2.819Zm-2.82 8.633c0-1.36-1.08-2.42-2.459-2.42s-2.459 1.08-2.459 2.42c0 1.34 1.1 2.42 2.46 2.42 1.359 0 2.459-1.04 2.459-2.42ZM35.468 8.199v9.816H32.65v-1.4c-.68 1.02-1.74 1.6-3.119 1.6-2.54 0-4.559-2.3-4.559-5.118 0-2.82 2.02-5.098 4.56-5.098 1.378 0 2.438.58 3.118 1.599v-1.4h2.82Zm-2.819 4.897c0-1.36-1.08-2.419-2.46-2.419-1.379 0-2.459 1.08-2.459 2.42 0 1.339 1.1 2.419 2.46 2.419 1.36 0 2.46-1.04 2.46-2.42Z"
        clipRule="evenodd"
      />
      <path d="M51.942 18.014h2.4L58.4 8.197h-3.079l-2.18 5.978-2.219-5.978h-3.059l4.079 9.817Z" />
      <path
        fillRule="evenodd"
        d="M68.656 8.199v9.816h-2.82v-1.4c-.679 1.02-1.738 1.6-3.118 1.6-2.54 0-4.559-2.3-4.559-5.118 0-2.82 2.02-5.098 4.559-5.098 1.38 0 2.44.58 3.119 1.599v-1.4h2.819Zm-2.82 4.897c0-1.36-1.08-2.419-2.459-2.419s-2.459 1.08-2.459 2.42c0 1.339 1.1 2.419 2.46 2.419 1.359 0 2.459-1.04 2.459-2.42Z"
        clipRule="evenodd"
      />
      <path d="M79.752 18.015v-5.559c0-2.779-1.52-4.458-4.039-4.458-1.12 0-2.04.54-2.66 1.48v-1.28h-2.818v9.817h2.819v-5.199c0-1.28.78-2.12 1.94-2.12 1.159 0 1.939.84 1.939 2.12v5.199h2.819ZM80.91 13.116c0-2.919 2.2-5.118 5.099-5.118 2.46 0 4.539 1.68 4.798 3.939h-2.859c-.2-.72-1-1.24-1.939-1.24-1.34 0-2.339 1.02-2.339 2.42s1 2.4 2.339 2.4c.94 0 1.76-.56 1.96-1.32h2.858c-.22 2.34-2.319 4.018-4.818 4.018-2.9 0-5.099-2.179-5.099-5.098Z" />
      <path
        fillRule="evenodd"
        d="M93.966 13.776h7.298c.04-.32.06-.78.06-1 0-2.699-2.18-4.778-4.839-4.778-2.86 0-5.098 2.158-5.098 5.058s2.26 5.159 5.158 5.159c2.46 0 4.319-1.38 4.639-3.5h-2.76c-.159.68-.859 1.16-1.959 1.16-1.319 0-2.299-.82-2.499-2.099Zm2.4-3.499c1.04 0 1.939.7 2.019 1.7h-4.279c.34-1.16 1.22-1.7 2.26-1.7ZM112.459 4.463v13.551h-2.819v-1.399c-.68 1.02-1.739 1.6-3.119 1.6-2.539 0-4.559-2.3-4.559-5.119s2.02-5.098 4.559-5.098c1.38 0 2.439.58 3.119 1.6V4.463h2.819Zm-2.819 8.633c0-1.36-1.079-2.42-2.459-2.42-1.38 0-2.459 1.08-2.459 2.42 0 1.34 1.099 2.42 2.459 2.42s2.459-1.04 2.459-2.42Z"
        clipRule="evenodd"
      />
      <path d="M133.092 18.015v-6.098c0-2.38-1.68-3.919-4.019-3.919-1.239 0-2.379.6-2.959 1.44-.6-1.02-1.5-1.44-2.779-1.44-.9 0-2.06.3-2.799 1.38v-1.18h-2.819v9.817h2.819v-5.438c0-1.28.819-1.88 1.739-1.88 1.04 0 1.7.72 1.7 1.88v5.438h2.819v-5.438c0-1.26.84-1.88 1.779-1.88 1.02 0 1.68.72 1.68 1.88v5.438h2.839Z" />
      <path
        fillRule="evenodd"
        d="M144.447 13.116c0 2.92-2.219 5.099-5.098 5.099s-5.098-2.18-5.098-5.099 2.199-5.118 5.098-5.118 5.098 2.2 5.098 5.118Zm-7.437 0c0 1.36.999 2.4 2.339 2.4 1.339 0 2.339-1.04 2.339-2.4 0-1.36-1.019-2.42-2.339-2.42s-2.339 1.02-2.339 2.42ZM155.583 18.014V4.464h-2.819v5.134c-.68-1.02-1.74-1.6-3.119-1.6-2.539 0-4.559 2.28-4.559 5.098 0 2.82 2.02 5.119 4.559 5.119 1.38 0 2.439-.58 3.119-1.6v1.4h2.819Zm-5.278-7.337c1.379 0 2.459 1.06 2.459 2.419 0 1.38-1.1 2.42-2.459 2.42-1.36 0-2.46-1.08-2.46-2.42 0-1.34 1.08-2.42 2.46-2.42ZM166.519 13.776h-7.297c.2 1.28 1.18 2.1 2.499 2.1 1.1 0 1.8-.48 1.959-1.16h2.759c-.319 2.12-2.179 3.499-4.638 3.499-2.899 0-5.159-2.26-5.159-5.16 0-2.899 2.24-5.057 5.099-5.057 2.659 0 4.838 2.08 4.838 4.778 0 .22-.02.68-.06 1Zm-2.879-1.8c-.079-1-.979-1.699-2.019-1.699s-1.92.54-2.259 1.7h4.278Z"
        clipRule="evenodd"
      />
      <path d="M174.056 10.677v-2.56c-.32-.059-.639-.119-.939-.119-1.1 0-1.96.48-2.539 1.32v-1.12h-2.82v9.817h2.82v-4.839c0-1.819 1.099-2.639 2.459-2.639.34 0 .68.06 1.019.14Z" />
      <path
        fillRule="evenodd"
        d="M184.373 8.199v9.816h-2.82v-1.4c-.679 1.02-1.739 1.6-3.118 1.6-2.54 0-4.559-2.3-4.559-5.118 0-2.82 2.019-5.098 4.559-5.098 1.379 0 2.439.58 3.118 1.599v-1.4h2.82Zm-2.82 4.897c0-1.36-1.079-2.419-2.459-2.419-1.38 0-2.459 1.08-2.459 2.42 0 1.339 1.099 2.419 2.459 2.419s2.459-1.04 2.459-2.42Z"
        clipRule="evenodd"
      />
      <path d="M189.79 13.976v-3.1h2.42V8.198h-2.42V5.618h-2.819v2.58h-1.819v2.679h1.819v3.139c0 2.638 1.56 4.158 3.879 4.158.399 0 .979-.06 1.359-.16v-2.599c-.22.06-.48.08-.72.08-.979 0-1.699-.46-1.699-1.52Z" />
      <path
        fillRule="evenodd"
        d="M202.465 18.215c2.879 0 5.099-2.18 5.099-5.099s-2.2-5.118-5.099-5.118-5.098 2.2-5.098 5.118c0 2.92 2.219 5.099 5.098 5.099Zm0-2.7c-1.34 0-2.339-1.04-2.339-2.399 0-1.4 1.019-2.42 2.339-2.42s2.339 1.06 2.339 2.42c0 1.36-1 2.4-2.339 2.4Z"
        clipRule="evenodd"
      />
      <path d="M218.259 12.457v5.558h-2.819v-5.199c0-1.28-.78-2.12-1.939-2.12s-1.939.84-1.939 2.12v5.199h-2.819V8.198h2.819v1.28c.62-.94 1.539-1.48 2.659-1.48 2.519 0 4.038 1.68 4.038 4.458Z" />
    </svg>
  )),
  { width: 220, height: 24 },
);
const AdvancedModerationHorizontal = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={AdvancedModerationHorizontalSvg} {...props} />
));
AdvancedModerationHorizontal.displayName = 'AdvancedModerationHorizontal';
export default AdvancedModerationHorizontal;
