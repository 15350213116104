import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const HashSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="m12.008 3.853-.757 6.814H4v2.666h6.954l-.592 5.334H4v2.666h6.066l-.725 6.52 2.65.294.758-6.814h5.317l-.725 6.52 2.65.294.758-6.814H28v-2.666h-6.955l.592-5.334H28v-2.666h-6.066l.724-6.52-2.65-.294-.757 6.814h-5.317l.724-6.52-2.65-.294Zm6.354 14.814.593-5.334h-5.317l-.593 5.334h5.317Z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 32, height: 32 },
);
const Hash = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={HashSvg} {...props} />
));
Hash.displayName = 'Hash';
export default Hash;
