import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const DeskNoteFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M2 4h20v3.088a3.25 3.25 0 0 0-1.546.863l-6.671 6.672L12.438 20H4.5A2.5 2.5 0 0 1 2 17.5V4Zm3 6h11V8H5v2Zm6 5H5v-2h6v2Z"
        clipRule="evenodd"
      />
      <path d="M15.246 20H19.5a2.5 2.5 0 0 0 2.5-2.5v-1.906l-3.623 3.623-3.13.783ZM21.869 9.366a1.248 1.248 0 1 1 1.765 1.765l-6.28 6.28L15 18l.589-2.354 6.28-6.28Z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const DeskNoteFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={DeskNoteFilledSvg} {...props} />
));
DeskNoteFilled.displayName = 'DeskNoteFilled';
export default DeskNoteFilled;
