import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const SparklesSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g>
        <path d="m12.738 5.333.526-1.167 1.167-.526a.333.333 0 0 0 0-.607l-1.167-.527-.526-1.173a.333.333 0 0 0-.607 0L11.604 2.5l-1.173.526a.333.333 0 0 0 0 .607l1.167.527.526 1.173c.12.26.494.26.614 0Zm-5.307 1L6.37 4a.667.667 0 0 0-1.213 0l-1.06 2.333-2.334 1.06c-.52.24-.52.98 0 1.213l2.334 1.06L5.158 12c.24.52.98.52 1.213 0l1.06-2.334 2.333-1.06c.52-.24.52-.98 0-1.213l-2.333-1.06Zm4.693 4.333-.526 1.167-1.167.527a.333.333 0 0 0 0 .606l1.167.527.526 1.173c.12.26.487.26.607 0l.527-1.166 1.173-.527a.333.333 0 0 0 0-.607l-1.167-.526-.526-1.174a.339.339 0 0 0-.614 0Z" />
      </g>
    </svg>
  )),
  { width: 16, height: 16 },
);
const Sparkles = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={SparklesSvg} {...props} />
));
Sparkles.displayName = 'Sparkles';
export default Sparkles;
