import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const LikeSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M14.347 3.463a6.382 6.382 0 0 1 4.778 0 6.273 6.273 0 0 1 2.03 1.324 6.122 6.122 0 0 1 1.364 1.989 6.016 6.016 0 0 1 0 4.709 6.123 6.123 0 0 1-1.363 1.989L12 22.396l-9.156-8.923A6.064 6.064 0 0 1 1 9.13c0-1.634.666-3.196 1.844-4.343A6.334 6.334 0 0 1 7.264 3c1.653 0 3.243.64 4.42 1.786l.316.308.316-.308a6.275 6.275 0 0 1 2.031-1.324ZM16.736 5c-.564 0-1.12.108-1.64.318-.52.21-.99.516-1.384.9L12 7.889l-1.712-1.669A4.334 4.334 0 0 0 7.264 5 4.334 4.334 0 0 0 4.24 6.22 4.064 4.064 0 0 0 3 9.13c0 1.087.443 2.135 1.24 2.911L12 19.604l7.76-7.563c.395-.385.707-.84.919-1.34a4.015 4.015 0 0 0 0-3.143 4.12 4.12 0 0 0-.919-1.339 4.274 4.274 0 0 0-1.384-.901A4.381 4.381 0 0 0 16.736 5Z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Like = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={LikeSvg} {...props} />
));
Like.displayName = 'Like';
export default Like;
