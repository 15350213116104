import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const OverviewFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M15 16v5h6V10.108a2.5 2.5 0 0 0-.983-1.987L12 2 3.983 8.121A2.5 2.5 0 0 0 3 10.108V21h6v-5a3 3 0 1 1 6 0Z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const OverviewFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={OverviewFilledSvg} {...props} />
));
OverviewFilled.displayName = 'OverviewFilled';
export default OverviewFilled;
