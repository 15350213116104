import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const HideSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M17.139 18.553 21.586 23 23 21.586 2.418 1.004 1.004 2.418 5.195 6.61c-.476.389-.91.793-1.298 1.192a18.314 18.314 0 0 0-2.592 3.393c-.09.155-.15.264-.176.314L.873 12l.256.491c.026.05.085.159.176.314a18.311 18.311 0 0 0 2.592 3.392C5.691 18.04 8.433 20 12 20c1.972 0 3.692-.6 5.139-1.447Zm-1.472-1.472-1.635-1.635a4 4 0 0 1-5.478-5.478L6.618 8.032c-.466.368-.896.763-1.288 1.166A16.32 16.32 0 0 0 3.154 12a16.321 16.321 0 0 0 2.176 2.802C6.945 16.461 9.203 18 12 18c1.352 0 2.578-.36 3.667-.919Zm-5.6-5.6a2.003 2.003 0 0 0 2.45 2.451l-2.45-2.45Z"
        clipRule="evenodd"
      />
      <path d="M20.26 16.034a18.389 18.389 0 0 0 2.435-3.229c.047-.08.093-.162.139-.245.018-.033.036-.066.051-.1l.242-.46-.24-.46c.015.028.01.02-.001-.001l-.052-.1a16.196 16.196 0 0 0-.671-1.09 18.31 18.31 0 0 0-2.06-2.547C18.309 5.961 15.566 4 12 4a9.682 9.682 0 0 0-3.22.553l1.618 1.618A7.589 7.589 0 0 1 12 6c2.797 0 5.055 1.54 6.67 3.198A16.312 16.312 0 0 1 20.846 12a16.413 16.413 0 0 1-2 2.619l1.414 1.415Z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Hide = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={HideSvg} {...props} />
));
Hide.displayName = 'Hide';
export default Hide;
