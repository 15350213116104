import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const BrCommunitySvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#br-co-a)">
        <path d="M4 21a8 8 0 1 1 16 0H4ZM12 11.5a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z" />
        <path d="M6.5 7.5a1.75 1.75 0 1 0 0 3.5H7v1.5h-.072A3.5 3.5 0 0 0 3.5 16h-1.5c0-1.809.96-3.394 2.399-4.272A3.25 3.25 0 0 1 6.5 6v1.5ZM17.5 7.5a1.75 1.75 0 1 1 0 3.5H17v1.5h.069a3.5 3.5 0 0 1 3.429 3.5h1.5c0-1.808-.96-3.392-2.397-4.27a3.25 3.25 0 0 0-2.1-5.73v1.5Z" />
      </g>
      <defs>
        <clipPath id="br-co-a">
          <path d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  )),
  { width: 24, height: 24 },
);
const BrCommunity = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={BrCommunitySvg} {...props} />
));
BrCommunity.displayName = 'BrCommunity';
export default BrCommunity;
