import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const TagFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M2 2.003 12.407 2l9.085 9.098a2.5 2.5 0 0 1-.001 3.537l-6.858 6.854a2.5 2.5 0 0 1-3.535 0L2 12.412V2.003Zm6.13 6.135a1.256 1.256 0 0 0 0-1.771 1.244 1.244 0 0 0-1.765 0 1.256 1.256 0 0 0 0 1.771c.488.49 1.277.49 1.765 0Z"
        clipRule="evenodd"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const TagFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={TagFilledSvg} {...props} />
));
TagFilled.displayName = 'TagFilled';
export default TagFilled;
