import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const TextSizeSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M1 6h6v14h2V6h6V4H1v2Z" />
      <path d="M13 10v2h4v8h2v-8h4v-2H13Z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const TextSize = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={TextSizeSvg} {...props} />
));
TextSize.displayName = 'TextSize';
export default TextSize;
