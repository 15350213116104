import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const KeyFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="m20.291 1.791 1.411 1.418-1.295 1.288 3.01 2.996-3.165 3.152a2.5 2.5 0 0 1-3.527 0l-1.247-1.241-2.758 2.744a6.481 6.481 0 0 1 1.276 3.838 6.469 6.469 0 0 1-1.91 4.614 6.52 6.52 0 0 1-4.63 1.9 6.542 6.542 0 0 1-4.617-1.93l-.007-.008A6.479 6.479 0 0 1 1 15.992a6.48 6.48 0 0 1 1.911-4.536 6.537 6.537 0 0 1 8.377-.705l9.003-8.96Z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const KeyFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={KeyFilledSvg} {...props} />
));
KeyFilled.displayName = 'KeyFilled';
export default KeyFilled;
