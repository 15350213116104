import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const BoxVerticalSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M21 3H3v8h18V3ZM21 13H3v8h16a2 2 0 0 0 2-2v-6Z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const BoxVertical = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={BoxVerticalSvg} {...props} />
));
BoxVertical.displayName = 'BoxVertical';
export default BoxVertical;
